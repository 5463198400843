@import "article";
@import "blockquote";
@import "footnote";
@import "jugement";
@import "marqueur";
@import "signature";
@import "table";
@import "titre";
@import "visa";

.progilex-v1 {
  padding-top: 15px;
  font-size: 14px;
  color: $blue;
  line-height: 1.5em;

  * {
    text-align: justify;
    font-size: 14px;
    color: $blue;

    sup {
      position: relative;
      font-size: 75%;
      line-height: 0;
      vertical-align: baseline;
    }
  }

  span {
    font-size: unset;
  }
}


/*
 * Cadre reprenant le style du contenu du document
 * =============================================================================
 */

.progilex-v1 {

  .highlight {
    font-weight: $fw-medium;
    background-color: $gray-lighter;
    color: $blue-darker;
  }
  .sup {
    display: inline;
    vertical-align: super;
  }
  .sub {
    display: inline;
    vertical-align: sub;
  }
  ol, ul {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  ul.dashedList {
    list-style-type: none;
    padding-left: 20px;
  }
  ul.dashedList > li {
    text-indent: -5px;
  }
  ul.dashedList > li:before{
    content: "-";
    margin-right: 5px;
  }
  .table {
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid gray;
    width: 100%;
    border-spacing: 0;
    display: table;
    border-collapse: separate;
  }
  .tableborderspecified {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .notcol {
    font-style: italic;
  }
  .td, .th {
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: gray;
  }
  .tr {
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: gray;
  }
  a {
    color: $blue-darker;
    font-weight: bold;
    -webkit-user-select: text;
    -moz-select: text;
    -ms-select: text;
    user-select: text;
  }
  h1, h2, h3 {
    a {
      font-size: unset !important;
      color: #D3AF7B !important;
    }
  }
}



/*
 * Cadre reprenant le contenu de tous les resumes
 * =============================================================================
 */
.progilex-v1 {

  .resume {
    display: block;
    padding: 15px;
    margin-top: 5px;
    margin-bottom: 15px;
    background-color: $gray-bg;
    border-radius: 5px;
  }

  .act > .resume:before {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
  }
}


/*
 * Cadre reprenant le contenu de tous les textes affiches
 * =============================================================================
 */
.progilex-v1 .content {
  margin: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}


/*
 * Affichage des listes dans les titres alignes a gauche pour la presentation
 * =============================================================================
 */
.progilex-v1 {
  .title-proj > table {
    display: block;
    text-align: left;
  }

  .ti-gr-alineas {
    display: block;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    color: $blue-darker;
    padding-bottom: 15px;
  }

  *[link='true'] {
    text-decoration: underline;
    color: $blue-darker;
  }
}


/*
 * FINAL CONVENTION
 * =============================================================================
 */
.progilex-v1 {
  .close-convention, .close-protocol {
    display: block;
    font-style: italic;
    margin-top: 10px;
  }

  .date-convention, .date-protocol {
    display: block;
    margin-top: 5px;
    font-weight: bold;
  }
}


/*
 * PROTOCOLE
 * =============================================================================
 */
.progilex-v1 .doc-parl {
  margin-top: 10px;
  font-size: 15px;
}


/*
 * UPLOADS
 * =============================================================================
 */
.progilex-v1 .custom-upload-file .uploadFile-padding {
  color: $blue-dark;
  font-weight: bold;
  font-family: $font-family;
  display: inline-block;
  margin-left: 10px;
}
.progilex-v1 .custom-upload-image {
  max-width : 600px;
  height: auto;
}

.progilex-v1 em.fa.fa-download {
  color: $blue-dark;
}

/*
 * COMPARISON
 * =============================================================================
 */

.caption-red-label {
  color: $comparison-deleted-text-color;
}

.caption-green-label {
  color: $comparison-added-text-color;
}



.progilex-v1 {
    .green-label {
        color: $comparison-added-text-color;
    }

    .red-label {
        color: $comparison-deleted-text-color !important;
        text-decoration: line-through;
    }
 }
