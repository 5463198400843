.my-searches-list {
 .section-title {
  div i {
   cursor: pointer;
  }

  div .fa-arrow-down {
   transform: rotate(0deg);
   transition: transform 0.1s linear;
  }

  div .fa-arrow-down.open {
   transform: rotate(180deg);
   transition: transform 0.1s linear;
  }
 }

 .header {
  display: flex;

  .control {
   min-width: 100px;

   input[type='checkbox'] {
    top: 2px;
   }
  }

  .title {
   flex-grow: 1;

   input {
    width: 98%;
   }
  }
 }

 .panel-heading {
  padding: 15px;

 }

 .panel-body {
  padding-right: 15px;

  .sub-section-container {
   .filter {
    .tag {
     margin: 2px;
    }
   }

   .control {
    padding-bottom: 10px;
    min-height: 32px;
   }
  }
 }
}