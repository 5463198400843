.adminUser {
  form {
    margin-block-end: 1em;
  }
  padding-top: 0 !important;
  height: auto;
  .dropdown {
    .btn {
      border: none;
      background: transparent;
      color: $gold;
      font-size: $fs-medium;
      &:hover, &:active {
        border: none;
        background: transparent;
        color: $gold-dark;
      }
      .caret {
        margin-left: 5px;
      }
    }
    .dropdown-menu {
      background-color: $white;
      border-radius: 10px;
      border: 1px solid $gray-lighter;
      box-shadow: 0 2px 2px $gray-shadow;
      min-width: 75px;
      > li > a {
        color: $blue;
        &:hover {
          background-color: transparent;
          color: $gold;
        }
      }
    }
  }

  .topcommands-panel {
    @include clearfix;
    .dropdown {
      float: left;
    }
    > .btn {
      float: left;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
    @media (max-width:550px) {
      .dropdown {
        float: none;
      }
    }
    @media (max-width:340px) {
      > .btn {
        clear: both;
        margin-top: 10px;
      }
    }
  }

  .admin-table {
    input[type='checkbox'] {
      top: 0;
    }

    tbody {
      tr:first-child {
        margin-top: 10px;
      }

      tr {
        height: 40px;
      }

      tr.company_manager_row{
            color: tint-color($blue, 20%);
      }
    }

    th:first-child, td:first-child {
      width: 8%;
    }
    th, td {
      width: 18%;
    }
    th:last-child, td:last-child {
      width: 17%;
    }
  }

  .btn.pull-right {
    margin-left: 10px;
    margin-right: 0;
  }
  .pagination-menu {
    margin-bottom: 30px;
  }
  .bottomDiv {
    @media (max-width: 767px) {
      .btn {
        margin-bottom: 20px;
      }
    }
  }
}
