/************ SEARCH CONTAINER ***********/
.autocomplete-container {
  background-color: red;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

/************ CONTEXTUAL MENU ***********/
.document-context {
  margin-top: -15px;

  .document-context-btn {
    background-color: transparent;
    color: $gray-light;
    font-size: $fs-biggest;
    border: none;
    transition: color $trans;

    &:hover {
      color: shade-color($gray-light, 10%);
    }
  }

  .document-context-menu {
    background-color: $white;
    border-radius: 5px;
    border: 1px solid $gray-lighter;
    box-shadow: 0 2px 2px $gray-shadow;
    left: auto;
    right: 0;
  }

  .document-context-item {
    white-space: nowrap;
    margin: 3px 20px;
    color: $blue;
    text-align: right;
    font-weight: 400;
    transition: color $trans;

    &:hover {
      color: $gold;
    }
  }
}

.documentDetail {
  padding: 0 !important;
  background: $white !important;

  #returnToResults {
    padding: 25px 0 0 58px;
    top: 0;
    left: 0;
    position: absolute;
  }

  .document-detail-content {
    padding: 0 4vw 4vw;
    background: white;
  }

  /******* DOCUMENT DETAIL NAVIGATION ********/
  .documentDetailNav {
    overflow-x: auto;
    white-space: nowrap;
    margin-left: -58px;
    padding-left: 58px;
    background-color: white;

    &--sticked {
      position: fixed;
      top: 0px;
      z-index: 11;
      left: 210px;
      padding-left: 56px;
      background: white;
    }

    @media (max-width: 992px) {
      &--sticked {
        padding-left: 30px;
      }
    }
    @media (max-width: 767px) {
      &--sticked {
        left: 0px;
        margin-top: 72px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      @include clearfix;

      li {
        margin-right: 20px;
        display: inline-block;

        a {
          display: block;
          padding: 20px 0;
          color: $blue;
          position: relative;
          transition: color $trans;
          font-weight: $fw-medium;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            transition: height $trans;
            background-color: $blue-darker;
          }
        }

        &.active {
          a {
            color: $blue-darker;

            &:before {
              height: 3px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .document-detail-header {
      padding: 110px 30px 0;
    }
    .document-detail-content {
      padding: 0 30px 30px;
    }
  }

  /******* DOCUMENT DETAIL ********/
  .document {
    width: 100%;
    position: relative;

    .actions {
      display: inline;

      form {
        .btn {
          margin: 2px;
          padding: 10px 18px 12px !important;
        }

        display: flex;
        flex-wrap: wrap;
      }

      &--sticked {
        position: fixed;
        top: 70px;
        z-index: 11;
        left: 210px;
        padding-left: 56px;
        background: white;
      }

      @media (max-width: 992px) {
        &--sticked {
          padding-left: 30px;
        }
      }
      @media (max-width: 767px) {
        &--sticked {
          left: 0px;
          margin-top: 72px;
        }
      }

      .documentButtons {
        padding-top: 30px;
        display: inline;

        .red {
          color: red;
        }

        form div.col-xs-12 {
          padding-left: 0px;
          padding-right: 0px;
        }

        .form-group {
          display: inline-block;
          white-space: nowrap;
          padding-top: 2px;
          margin-bottom: 0px;
        }

        .documentVersion {
          display: inline;
          margin-left: 3px;
          width: 170px;

          .red {
            color: red;
          }
        }
      }

      .occurrences-bar {
        display: inline;
        padding-left: 0px;
        margin-left: auto;

        .occurences-count {
          font-weight: bold;
        }

        .btn-group {
          .btn {
            padding: 10px 4px 12px;

            span {
              left: 0 !important;
            }
          }
        }

        .occurences-number {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          font-weight: 500;
        }
      }
    }

    .document-content {

      .progilex-v1
      {
        a {
          color: #0e6c8e;
          font-weight: bold;
        }
      }

      flex: 3 3 100%;

      .documentTitle {
        line-height: 1.5;
        color: $blue-darker;
        margin-bottom: 15px;
      }
    }

    .documentType {
      font-weight: $fw-bold;
      color: $blue;
      font-size: $fs-medium;

      span {
        color: $blue;
        font-weight: $fw-regular;
      }
    }

    .documentEndDate {
      color: $blue;
      font-weight: $fw-bold;
    }

    .partner {
      color: $blue;
      text-align: right;
      line-height: 2;
    }

    .panel-content {
      width: 100%;
      margin: 5px;
    }

    .lx-document-padding {
      @media(min-width: 430px) {
        padding-right: 160px;
      }
    }

    &.data-sheet {
      label {
        font-size: $fs-smallest;
        font-weight: $fw-bold;
        line-height: 1;
        margin-bottom: 10px;
        color: $blue;
        text-transform: uppercase;
      }

      .value {
        font-size: $fs-big;
        font-weight: $fw-regular;
        line-height: 1;
        color: $blue;
        margin-bottom: 20px;

        p {
          font-size: $fs-big;
        }
      }

      .resume {
        height: auto;
        margin-bottom: 30px;
      }

      .col-xs-12 {
        .value {
          color: $blue-darker;
          font-size: $fs-bigger;
          line-height: 1.5;
          font-weight: $fw-bold;
          margin-bottom: 40px;
          border-bottom: 1px solid $gray-lighter;
          padding-bottom: 40px;
          height: auto;
        }
      }

      .book-thumbnail {
        height: 200px;
      }
    }

    @media (max-width: 1070px) {
      .actions {
        .documentButtons {
          .btn {
            span {
              display: none !important;
            }
          }
        }
      }
    }

    @media (max-width: 1300px) {
      .actions {
        .documentButtons {
          @include clearfix;
          text-align: right;
        }

        .occurrences-bar {
          display: none;
        }
      }
    }
    @media (max-width: 540px) {
      .actions {
        .form-group {
          float: none;
          margin-bottom: 0;
        }
      }
    }
  }

  .submenu {
    width: 100%;

    .header {
      float: left;
      display: block;
      padding: 0 0 0 10px;
      height: 40px;
    }
  }


  .row {
    font-size: $normal-text;
  }

  .documentVersion {
    margin-right: 0;
    margin-bottom: 0;
  }


}

.documentDetail > .tab-content .panel-body img {
  min-width: 20%;
  max-width: 100%;
}

.forbidden-content {
  padding-bottom: 15px;
  line-height: 1.5em;
  font-size: 1.6em;
}

.footerLegitech {
  border-top: solid 1px $gray-lighter;
  padding-top: 6px;
  font-size: 10px;
  color: $blue;
}

ul.dashedList {
  list-style-type: none;

  li {
    text-indent: -5px;
  }

  li:before {
    content: "-";
    display: inline-block;
    width: 10px;
    margin-left: -10px;
  }
}


/************ COMPARISON TEXT ***********/
.red-square {
  background-color: $comparison-deleted-text-color;
  margin-top: 5px;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  display: inline-block;
}

.green-square {
  background-color: $comparison-added-text-color;
  margin-top: 5px;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  display: inline-block;
}

.caption-red-label, .caption-green-label {
  display: inline-block;
}

.red-label, caption-red-label {
  color: $comparison-deleted-text-color;
}

.red-label {
  text-decoration: line-through;
}

.green-label, caption-green-label {
  color: $comparison-added-text-color;
}

.document-compare {
  .actions {
    .documentButtons {
      .btn-occurrences {
        font-size: 12px !important;
        padding: 8px 15px 10px !important;
        width: auto !important;
      }
    }
  }
}

a.visited-link {
  font-style: italic;
}
