.adminCode {
  .filter-panel {
    margin-top: 30px;
    label {
      line-height: 35px;
    }
    .btn:last-child {
      margin-right: 20px;
    }
  }

  table {
    th, td {
      width: 20%;
    }
    th:last-child, td:last-child {
      width:10%;
      padding-top: 10px;
      vertical-align: middle;
    }
  }
}
