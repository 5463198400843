@use '@angular/material' as mat;
@import "./modules/fonts";
@import "./modules/colors";
@include mat.core();

$black-87-opacity: rgba(black, 0.87);
$white-87-opacity: rgba(white, 0.8);

$custom-gold-palette: (
  50: $gold-lightest,
  100: $gold-lighter,
  200: $gold-light,
  300: $gold-light,
  400: $gold,
  500: $gold,
  600: $gold,
  700: $gold-dark,
  800: $gold-dark,
  900: $gold,
  A100: $gold,
  A200: $gold,
  A400: $gold,
  A700: $gold,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$custom-blue-palette: (
  50: $blue-dark,
  100: $blue-dark,
  200: $blue-darker,
  300: $blue-darker,
  400: $blue-darkest,
  500: $blue-darkest,
  600: $blue-darkest,
  700: $blue-darkestest,
  800: $blue-darkestest,
  900: $blue-darkestest,
  A100: $gold,
  A200: $gold,
  A400: $gold,
  A700: $gold,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$my-primary: mat.define-palette($custom-gold-palette);
$my-accent: mat.define-palette($custom-blue-palette);
$my-warn: mat.define-palette(mat.$red-palette);

$my-typography: mat.define-typography-config(
  $font-family: $font-family
);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    warn: $my-warn,
  ),
  typography: $my-typography,
));
@include mat.all-component-themes($my-theme);

.cdk-overlay-container {
  z-index: 1100;
}
.mat-list-item {
  font-family: $font-family;
}
.mat-hide-steppers {
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
}

.mat-card {
  font-family: $font-family;
}

.mat-mdc-form-field{
  &.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
    background-color: #0000000a;
    .mdc-line-ripple::before {
      border-bottom-color: transparent;
    }
  }
  label, .mat-mdc-select, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    font-size: $small-text;
    letter-spacing: 0;
    font-weight: 300;
  }
  &.normalize-mat-form-field {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.mat-mdc-chip {
  font-weight: 300;
}

.mdc-list-item, .mat-mdc-option {
  font-size: $small-text;
  letter-spacing: 0;
  font-weight: 300;
}

.mat-mdc-icon-button {
  i.fa {
    font-size: $large-text;
  }
}

.mat-mdc-slide-toggle {
  input[type='checkbox'] {
    display: none !important;
  }
}

.mat-mdc-dialog-title {
  padding: 15px 20px !important;
}
.mat-mdc-dialog-actions {
  padding: 8px 20px !important;
}

.mdc-snackbar {
  &.mat-toast-success-container {
    .mdc-snackbar__surface {
      background-color: $gold-dark;
    }
  }
  &.mat-toast-error-container {
    .mdc-snackbar__surface {
      background-color: $red;
    }
    .mat-mdc-snack-bar-action {
      color: white !important;
    }
  }
  .mat-mdc-snack-bar-label {
    color: white;
    font-weight: 500;
  }
  .mat-mdc-snack-bar-action {
    font-weight: 300;
  }
}

.mdc-linear-progress__bar-inner {
   left: 0;
}

.mdc-button .mdc-button__label {
  letter-spacing: 0;
}

.tooltip-overlay  {
  padding: 10px;
  border-radius: 4px;
  background-color: $blue-darkest;
  color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  margin: 0 20px;
  p {
    color: white;
    font-size: $fs-smallest;
    line-height: $fs-medium;
    margin-bottom: 0;
  }
}
