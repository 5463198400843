@import 'src/assets/scss/modules/colors';
@import 'src/assets/scss/modules/fonts';
@import 'src/assets/scss/modules/mixins';

.terms-and-agreements {
  .container {
    margin: auto !important;
  }

  .hello-text {
    text-align: center;
    max-width: 640px;
    line-height: 60px;
    font-size: $fs-biggest;
    font-weight: $fw-bold;
    color: $blue-dark;
    margin: 0 auto 5px;

    img {
      width: 60px;
      height: 60px;
      margin-right: 30px;
    }
  }

  object {
    padding-bottom: 1.5em;
    padding-top: 1.5em;
    border-radius: 5px;
  }

  .footer {
    width: 640px;
    margin: 30px auto;
    overflow: hidden;
    text-align: center;

    button {
      outline: none;
    }

    .negative {
      margin: 0 10px;
    }

    .positive {
      margin: 0 10px;
    }
  }

  .cgu-link {
    margin: 15px auto;
    width: 640px;
    text-align: left;
    font-size: $fs-small;
  }

  .sentence-agreement {
    margin: 15px auto;
    width: 640px;
    text-align: left;

    input {
      float: left;
    }

    label {
      display: block;
    }
  }
}

.cgu-viewer {
  width: 100%;
  height: fit-content;
}


.pdf-container {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;

  .pdfViewer {
    overflow-y: hidden;
  }

  .page {
    box-sizing: content-box;
  }

  .page-input {
    width: 60px;
  }

  @media(max-width: 700px) {
    flex-flow: column nowrap;
  }

  .menu-list {
    width: 100%;
    max-width: 320px;
    padding-left: 25px;
    padding-top: 20px;
    margin-right: 15px;
    background-color: $gray-bg;
    z-index: 10;
    // animation: bounceInLeft 0.5s;

    .book-summary-content {
      height: calc(100vh - 350px);
      overflow-y: scroll;
      padding-right: 10px;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
      }
    }
    #btn-close-menu {
      height: auto;
      padding: 6px 15px;
      &:hover {
        .mat-icon {
          color: white;
        }
      }
    }
    @media (max-width: 992px) {
      position: absolute;
      height: 100%;
      box-shadow: 4px 0 10px rgb(0 0 0 / 10%);
    }
    @media (min-width: 993px) {
      border-radius: 20px;
    }
  }

  .ng2-pdf-viewer-container {
    height: fit-content !important;
    overflow-y: visible !important;
  }

  .pdf-nav-reader-container {
    // flex: 3 5 75%;
    // margin-top: 20px;
    // margin-left: 10px;
    width: 100%;

    @media (max-width: 767px) {
      .pdf-nav-reader-container-content{
        flex-direction: column-reverse;
      }
    }

    #navPdf {
      flex-flow: column wrap;
      width: 100%;
      display: table;
      @include clearfix;

      .float-start.margin-right10 {
        @media (max-width: 700px) {
          width: 265px;
          margin-left: -10px;
        }
      }

      .btn {
        min-width: 80px;
        @media(max-width: 700px) {
          min-width: 80px;
          max-width: 140px;
        }
      }

      .btn-open-menu {
        min-width: 50px;
        max-width: 57px;
      }

      .btn.btn-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        padding: 5px 0 7px;
        text-align: center;

      }

      .float-start {
        @include clearfix;

        .btn {
          border-radius: 0;
          margin: 0;
          float: left;
          border-left-width: 0;
          border-right-width: 0;
        }

        .btn:first-child {
          border-radius: 20px 0 0 20px;
          border-left-width: 2px;
          border-right-width: 2px;
        }

        .btn:last-child {
          border-radius: 0 20px 20px 0;
          border-left-width: 2px;
          border-right-width: 2px;
        }

        .btn.btn-icon {
          padding: 16px 0 18px;

          svg {
            width: 13px;
            height: 13px;
          }

        }
      }

      .marginLeftVh {
        margin-left: 60vh;
        opacity: 0;
      }

      .float-end {
        .page {
          line-height: 40px;
        }

        .btn:first-child {
          margin-right: 0;
          margin-left: 0;
          border-radius: 0 20px 20px 0;
          float: right;
          border-left-width: 0;
        }

        .btn:last-child {
          margin-right: 0;
          border-radius: 20px 0 0 20px;
          float: right;
        }
      }

      .btn-primary {
        span {
          top: -3px !important;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      .page {
        display: inline-block;
      }

      table td input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button, input[type=number] {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .pdf-viewer-wrapper {
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
    }
    .pdf-search-bar-wrapper{
      position: absolute;
      z-index: 11;
      top: -45px;
      box-shadow: 0 0 10px rgb(0 0 0 / 15%);
      padding: 10px;
      background-color: white;
      border-radius: 10px;
    }
    @media (max-width: 768px) {
      padding: 0;
      margin-bottom: 20px;
    }
  }

  .pdf-viewer-container {
    overflow: unset;
    min-width: 200px;
    min-height: 200px;
    max-height: 100%;
    height: calc(100vh - 240px);
    width: 100%;
    max-width: 1800px;

    pdf-viewer {
      margin: 0 auto;
      display: block;
      user-select: text !important;
      touch-action: auto !important;

      .ng2-pdf-viewer-container {
        overflow: visible;
        position: absolute;
        background-color: #f7f7f7;
        border-radius: 20px;
        border: 10px solid #f7f7f7;
        border-top: 0;
        border-bottom: 0;

        .page {
          box-sizing: content-box;
        }
      }

      span.highlight.selected {
        background-color: yellow;
      }

      .annotation-layer{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        opacity: 0.2;
        line-height: 1.0;
      }
      .annotation-layer>section {
        color: transparent;
        position: absolute;
        white-space: pre;
        cursor: text;
        transform-origin: 0 0;
      }

      .annotation-layer>.linkAnnotationHL>a {
        position: absolute;
        font-size: 1em;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: yellow;
        cursor: pointer
      }

      .annotation-layer>.linkAnnotationRejected>a {
        position: absolute;
        font-size: 1em;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: red;
        cursor: pointer
      }

      .annotation-layer>.linkAnnotation>a {
        position: absolute;
        font-size: 1em;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: yellow;
        cursor:pointer;
      }

      .highlightAnnotation14 {
        background: rgba(255, 255, 0, .2);
        padding: 5px;
        border: 1px dotted black;
      }
    }
  }

  .pdf-reader-actions {
    background-color: #f7f7f7;
    border-radius: 20px;
    padding: 10px;
    width: 100% !important;
    .pdf-reader-action {
      margin: 0 8px;
      text-align: center;
      .form-field {
        width: 80px;
        border-radius: 20px;
        overflow: hidden;
        .mat-form-field-wrapper{
          padding-bottom: 0;
        }
        .mat-form-field-flex {
          padding: 0 0.75em;
        }
        .mat-form-field-underline{
          display: none;
        }
        input {
          -moz-appearance: textfield;
          &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
            display: none;
          }
        }
      }
      button {
        background-color: white;
      }
      .chat-bot-button-container {
        position: relative;
        .bot-chat-button {
          background-color: $blue-darkest;
          position: relative;
          z-index: 2;
          i {
            color: $gold;
            margin-top: 4px;
            font-size: 19px;
          }
        }
        .bot-chat-button-animation {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border-radius: 50%;
          background-color: $blue-darkest;
          z-index: 1;
          animation-name: pulseAnimation;
          animation-duration: 1.5s;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
        }
        .deactivate-mode-icon {
          position: relative;
          font-size: 18px;
          color: $gold;
          &:after {
            content: "";
            position: absolute;
            width: 4px;
            height: 31px;
            background-color: $gold;
            display: block;
            left: 10px;
            bottom: -6px;
            border-right: 2px solid white;
            transform: rotate(45deg);
            transform-origin: center;
          }
        }
      }
    }
    @media (max-width: 767px) {
      display: flex;
      overflow-x: scroll;
      margin-bottom: 20px;
    }
    @media (min-width: 768px) {
      width: unset !important;
      padding: 20px 10px;
      .pdf-reader-action {
        margin: 15px 0;
      }
    }
  }
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


@media (max-width: 680px) {

  .terms-and-agreements {
    .hello-text {
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 40px;

      img {
        float: left;
      }
    }

    .cgu-link {
      margin: 15px;
      width: auto;
    }

    .sentence-agreement {
      margin: 15px;
      width: auto;
    }

    .footer {
      width: auto;
    }

    #navPdf {
      width: auto;
      margin-left: 15px;
    }

  }
}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
