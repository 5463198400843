.spinner {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2025;
    background-color: rgba(255, 255, 255, 0.5);
    display: none;

    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -65px;
        margin-top: -65px;
        width: 130px;
        height: 130px;
        padding: 30px;
        border-radius: 10px;
        background-color: $white;
        box-shadow: 0 12px 22px $gray-shadow-big;
        text-align: center;
        svg {
            margin-top: 10px;
            height: 32px;
            animation: rotatePlane 1.2s infinite ease-in-out;
        }
    }
}

@keyframes rotatePlane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    } 50% {
          transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      } 100% {
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        }
}


