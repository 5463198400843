$dark-gray: #55575b;     /*buttons, icons navigation*/
$gray: #76787b;
$light-gray: #cecfd1;    /*highlighted tab (recherche avancee)*/
$lighter-gray: #eeeeee;  /*navigation option unselected background*/
$very-light-gray: #f5f5f5; /*subtitle menu*/
$footer-gray: #f7f7f7;   /*background navigation panel, footer*/

$dark-yellow: #f4cd24;   /*background for selected navigation option*/
$yellow: #f9e58f;        /*background for found words on the result page*/
$light-yellow: #fcf0bc;  /*background for Headers (e.g. Recherche Avancee)*/
$lighter-yellow: #fdf6d6; /*background for buttons on modals*/

$comparison-added-text-color: #088A08; /*text color for comparison page*/
$comparison-deleted-text-color: #8A0808; /*text color for comparison page*/

$white: #ffffff;
$black: #000000;
$green: #339999;
$red: #cc3333;
$text-color: $dark-gray;
$transparent: transparent;

$gray-bg: #f7f7f7;
$gray-light: #BCC1CB;
$gray-lighter: #E5E5E5;
$gray-lightest: #F5F5F5;
$gray-shadow: rgba(30, 48, 81, .06);
$gray-shadow-big: rgba(30, 48, 81, .09);
$gold: #D3AF7B;
$gold-light: #dbbf95;
$gold-lighter: #e4cfb0;
$gold-lightest: #f1e7d7;
$gold-dark: #BC9C6E;
$blue: #6D798E;
$blue-dark: #384a6e;
$blue-darker: #1E3051;
$blue-darkest: #1d3051;
$blue-darkestest: #152642;
$highlight: #b400aa;



/* Nav bar */
$navbar-title-background-color: $dark-gray;
$navbar-title-color: $lighter-gray;
$navbar-subtitle-background-color: $lighter-gray;
$navbar-subtitle-background-color-hover: $dark-yellow;
$navbar-subtitle-background-color-active: $dark-yellow;
$navbar-subtitle-color: $dark-gray;


/* Action button */
$button-action-background-color: $dark-gray;
$button-action-color: $white;


/* Cookies */
$button-cookies-background-color: $lighter-gray;
$button-cookies-color: $dark-gray;
$banner-cookies-background-color: $dark-gray;
$banner-cookies-color: $lighter-gray;


/* Header */
$header-background-color: $light-yellow;
$header-color: $dark-gray;

$material-gray: #e0e0e0;
