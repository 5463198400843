.bandeau-cookies {
    position: fixed;
    height: $header-menu-height;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: white;
    border-top: 1px solid $gray-lighter;

    div {
        color: $blue;
    }
    .cookies-content {
        margin: auto;
        padding: 16px 58px;
        line-height: 40px;

        p {
            color: $blue;
            margin-bottom: 0;
            text-align: left;
            font-size: $smaller-text;
            display: inline-block;
            width: calc(100% - 120px);
            a {
                color: $blue-dark;
            }
        }

    }
    .cookies-btn {
        display: inline-block;
        margin: 0;
        float: right;
    }
    @media (max-width: 1160px) {
        .cookies-content {
            line-height: 20px;
        }
    }
    @media (max-width: 992px) {
        .cookies-content {
            padding: 16px 30px;
        }
    }
    @media (max-width: 767px) {
        left: 0;
    }
    @media (max-width: 540px) {
        height: auto;
        .cookies-content {
            p {
                width: 100%;
                margin-bottom: 10px;
            }
            .cookies-btn {
                float: none;
            }
        }
    }
}
