.adminView {
    padding-top: 0 !important;
    height: auto;
  
    .dropdown {
      .btn {
        border: none;
        background: transparent;
        color: $gold;
        font-size: $fs-medium;
        &:hover, &:active {
          border: none;
          background: transparent;
          color: $gold-dark;
        }
        .caret {
          margin-left: 5px;
        }
      }
      .dropdown-menu {
        background-color: $white;
        border-radius: 10px;
        border: 1px solid $gray-lighter;
        box-shadow: 0 2px 2px $gray-shadow;
        min-width: 75px;
        > li > a {
          color: $blue;
          &:hover {
            background-color: transparent;
            color: $gold;
          }
        }
      }
    }
  
    table {
      width: 100%;
      border-spacing: 0;
      border-radius: 10px;
      border: none;
      box-shadow: 0 2px 2px rgba(30, 48, 81, 0.06);
      background-color: #ffffff;
      margin-bottom: 30px;
      margin-top: 30px;
      input[type='checkbox'] {
        top: 0px;
      }
  
      thead {
        height: 40px;
        line-height: 40px;
        tr {
          width: 100%;
        }
      }
  
      tbody {
        tr:first-child {
          display: block;
          margin-top: 10px;
        }
  
        tr {
          height: 35px;
        }
      }
  
      th:first-child, td:first-child {
        width: 10%;
      }
      th, td {
        height: 50px;
        padding-top: 10px;
        vertical-align: middle;
        float: left;
        padding-left: 20px;
      }
      th:last-child, td:last-child {
        padding-top:0;
        padding-right: 20px;
      }
    }
    .btn-icon {
      margin-left: 20px;
      margin-right: 0;
    }
}