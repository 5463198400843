
.facebook-icon-url {
    @include imageLexnow($facebook-icon-url);
    width:24px;
    height:24px;
    float:left;
    margin-right: 5px;
}
.linkedin-icon-url {
    @include imageLexnow($linkedin-icon-url);
    width:24px;
    height:24px;
    float:left;
    margin-right: 5px;
}
.twitter-icon-url {
    @include imageLexnow($twitter-icon-url);
    width:24px;
    height:24px;
    float:left;
    margin-right: 5px;
}
.mail-icon-url {
    @include imageLexnow($mail-icon-url);
    width:24px;
    height:24px;
    float:left;
    margin-right: 5px;
}

.container-footer {
    .footer-logo {
        float: right;

        span { color: $black; }

        img {
            width: 100px;
            top: 0;
        }
    }

    .footer-align {
        white-space: nowrap;
        text-align: center;
        font-size: $footer-text;
    }

    .footer-media {
        padding-top: 10px;
    }
}
