
/*
 * BLOCKQUOTE
 * =============================================================================
 */
.progilex-v1 {

  .blockquote {
    background: #f9f9f9;
    border-left: 2px solid #ccc;
    margin: 1.5em 6px;
    padding: 0.5em 6px;
    quotes: "\201C""\201D""\2018""\2019";

    p {
      display: inline;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }
  }

  .blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 2em;
    line-height: 0.1em;
    margin-right: 0.2em;
    vertical-align: -0.4em;
  }

  .blockquote:after {
    color: #ccc;
    content: close-quote;
    font-size: 2em;
    line-height: 0.1em;
    margin-left: 0.2em;
    vertical-align: -0.4em;
  }

  blockquote {
    display: block;
    padding: 10px 20px;
    margin: 0 0 20px;
    border-left: 5px solid #eee;
    quotes: '\201C''\201D';

    .ARTICLE {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .NUM-ARTICLE, .NUM-ARTICLE p {
      display: inline;
      margin: 0 0 0 5px;
      font-weight: $fw-medium;
      font-size: 14px;
    }

    .PARAG {
      display: block;
      padding-top: 5px;
      padding-left: 10px;

      .NO-PARAG, .NO-PARAG p {
        display: inline-block;
        margin: 0 5px 0 0;
        color: $blue;
        font-size: 14px;
        font-weight: bold;

        .texte {
          display: inline;
        }
      }
    }

    .PARAG > .ALINEA {
      display: inline;
      padding-top: 5px;
      padding-bottom: 5px;

      .texte, p {
        display: inline;
      }
    }
  }

}
