$small-footer-height: 40px;
$large-footer-height: 50px;

$header-menu-bottom-stripe-height: 4px;
$header-menu-height: 72px;

$header-height: 40px;
$large-login-height: 50px;
$small-login-height: 40px;

$content-width: 79%;

$trans: .2s cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easing: cubic-bezier(0.215, 0.610, 0.355, 1.000);

