
/*
 * SIGNATURE
 * =============================================================================
 */
.progilex-v1 {

  .signatures {

    .signature {

      margin-left: 20px;

      .date-signature {
        font-style: italic;
        display: block;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .person-signature {
        margin-top: 5px;
        margin-left: 15px;

        .title-signature {
          display: block;
          font-style: italic;
        }
        .name-signature {
          display: block;
          font-weight: 700;
          margin-left: 5px;
        }
      }
    }
  }
}