/************* LEGAL NOTICES ***************/

.legalNotices {
  margin: 20px 10px 10px;
}

/************* HOME PAGE OFFLINE ***************/

.link-title {
  color: #D3AF7B !important;
}

.link-title:hover {
  color: #1e3051 !important;
}

.content-title-home-mr {
  display: inline-block;
  @media (min-width: 450px) {
    margin-left: 50px;
  }
}

.content-title-home {
  display: inline-block;
}

.card-params {
  display: flex;
  flex-direction: column;

  > div {
    flex-grow: 1;
  }

  @media (min-width: 450px) {
    height: 280px;
    width: 200px;
  }
}

.card-size {
  width: 260px !important;

  @media (max-width: 450px) {
    width: 100% !important;
  }
}

.thumbnail-card {
  margin-left: 60px;
  height: auto;
  max-width: 250px;
  width: 50%;
}

.empty-thumbnail-card {
  margin-left: 100px;
  width: 20px;
  height: 130px;
}

/************* HOME PAGE ONLINE ***************/


.container-content #content #mainContent.home {
  padding: 0;

  .container {
    h3 {
      color: $gold;
      text-transform: uppercase;
      font-size: $fs-small;
      font-weight: $fw-bold;
      margin-bottom: 30px;
    }

    .updates-title {
      margin-top: 0;
      margin-bottom: 20px;
      margin-left: 15px;
    }

    .lastUpdates {
      .panel {
        min-height: 10px;
        padding: 0;
      }

      .panel-heading {
        background-color: $light-yellow;
      }

      .panel-body {
        padding: 0;

        .table {
          margin-bottom: 0;

          td {
            padding: 5px 0 5px 10px;
            width: 85%;
            vertical-align: middle;
          }
        }
      }
    }

    .resultSearch {
      margin-top: 15px;
    }

    .outOfSubscription {
      padding: 148px 58px 100px;
      font-size: $fs-small;
      font-weight: $fw-medium;
      line-height: 1.3;
      @media (max-width: 992px) {
        padding: 148px 30px 100px;
      }
    }
  }
}
