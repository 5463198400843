
/*
 * VISAS
 * =============================================================================
 */
.progilex-v1 {
  .visas, .consids {
    margin-top: 5px;
    display: block;
  }

  .visa {
    color: $blue-darker;
    font-size: 14px;
    padding-left: 5px;

    p {
      margin: 0;
      font-size: 14px;
    }
  }

  .consid {
    padding-top: 5px;
    font-style: normal;
  }

  .intro-visas, .preambule {
    display: block;
    padding-bottom: 10px;

    p {
      display: block;
      font-size: 14px;
      font-style: italic;
      margin: 0;
    }
  }

  .final-visas {
    font-size: 14px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 10px;
  }

  .close-articles {
    font-size: 13px;
    text-align: right;
    font-style: italic;
    padding-top: 5px;
    padding-bottom: 10px;
    margin: 0;
  }
}