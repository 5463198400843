/********************** CONTACT **********************/

.send-image-disabled {
  @include imageLexnow($send-image-disabled);
  width: 24px;
  height: 24px;
  float: left;
  margin-right: 5px;
  margin-top: -4px;
}

.send-image-active {
  @include imageLexnow($send-image-active);
  width: 24px;
  height: 24px;
  float: left;
  margin-right: 5px;
  margin-top: -4px;
}

.container.contact {
  .form-group {
    margin-bottom: 10px;
    font-size: $normal-text;
  }

  .lastGroup {
    margin-top: 20px;

    .leftText {
      line-height: 35px;

      .faWebfont {
        line-height: 25px;
      }
    }
  }

  .address {
    font-weight: $bold-font-weight;
    margin-bottom: 25px;
  }
}

@media all and (max-width: 1199px) {
  .emptyCol {
    border: 1px solid;
    width: 100%;
    margin: 0 0 20px 0;
    float: left;
  }
}


/********************** MENTIONS LEGALES **********************/

.legalNotices {
  p {
    font-size: $small-text;
  }

}
