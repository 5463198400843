.adminCompany {
  padding-top: 0 !important;
  height: auto;

  .dropdown {
    .btn {
      border: none;
      background: transparent;
      color: $gold;
      font-size: $fs-medium;
      &:hover, &:active {
        border: none;
        background: transparent;
        color: $gold-dark;
      }
      .caret {
        margin-left: 5px;
      }
    }
    .dropdown-menu {
      background-color: $white;
      border-radius: 10px;
      border: 1px solid $gray-lighter;
      box-shadow: 0 2px 2px $gray-shadow;
      min-width: 75px;
      > li > a {
        color: $blue;
        &:hover {
          background-color: transparent;
          color: $gold;
        }
      }
    }
  }

  .filter-panel {
    margin-top: 30px;
    label {
      line-height: 35px;
    }
  }

  .topcommands-panel {
    @include clearfix;
    .dropdown {
      float: left;
    }
    > .btn {
      float: left;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
    @media (max-width:550px) {
      .dropdown {
        float: none;
      }
    }
    @media (max-width:340px) {
      > .btn {
        clear: both;
        margin-top: 10px;
      }
    }
  }

  table {
    input[type='checkbox'] {
      top: 0px;
    }


    th:first-child, td:first-child {
      width: 10%;
    }
    th, td {
      width: 35%;
    }
    th:last-child, td:last-child {
      width: 20%;
    }
  }
  .pagination-menu {
    margin-bottom: 30px;
  }
  .bottomDiv {
    .btn {
      margin-left: 20px;
      margin-right: 0;
    }
    @media (max-width: 1102px) {
      .btn {
        margin-bottom: 20px;
      }
    }
  }
}
