.page-input{
    width: 60px;
}

.buy{
    table{
        width:100%;
    }
}

.bookLicenses {
    .res-table-container {
        table {
            thead {
                tr {
                    th {
                        background-color: #f3f3f3;
                        border-bottom: 1px solid $gray-lighter;
                    }
                }
            }
        }
    }
}

.unitPrice {
    line-height: 30px;
}

.book-content {
    position: relative;
}
.book-content .book-summary {
    float: left;
    top: 30px;
    position: sticky;
    padding: 0 20px 0 5px;
    max-width: 28%;
    height: 100%;
    border-right: 1px solid $gray-lighter;
}
.book-summary-scroll {
    overflow-y: auto;
    padding-bottom: 30px;
}
.book-summary-scroll #book-summary-icon-down,
.book-summary-scroll #book-summary-icon-up {
    display: none;
    position: absolute;
    text-align: center;
    left: 175px;
}
.book-summary-scroll #book-summary-icon-down {
    top: 70px;
}
.book-summary-scroll #book-summary-icon-up {
    bottom: 150px;
}
.book-summary-scroll ul {
    width: 90%;
}
.book-summary-scroll ul li {
    margin: 16px 1px 16px 1px;

    .link{
        color: $blue;
        font-weight: 500;
        &:before {
            height: 2px;
            background-color: $gray-lighter;
        }
        &:after {
            height: 2px;
            background-color: $blue-darker;
        }
        &:hover {
            &:after {
                height: 2px;
                background-color: $blue-darker;
            }
        }
    }
}
.book-summary-scroll ul li.active a{
    color: $blue-darker;
    font-size: 16px;
}
.book-content .bookPart-content {
    width: 100%;
}
