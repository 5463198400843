@import 'src/assets/scss/modules/colors';
@import 'src/assets/scss/modules/fonts';

.container-account {
  max-width: 1000px !important;
}

button {
  font {
    vertical-align: unset;
  }
}

a.document-link,
a.document-link:hover {
  color: $green;
  cursor: pointer;
  text-decoration: underline;
}

a.document-link:active,
a.document-link:visited {
  color: $green;
  cursor: pointer;
  text-decoration: none;
}

.password-set {
  margin: auto;
  width: 290px;
}

.fa-fa-asterisk {
  margin-right: 4px;
}

.account-settings {
  input[type='checkbox'] {
    display: none;
  }

  #alternativeEmail {
    max-width: 420px;
  }

  .link-agreement {
    font-size: $fs-small;
    line-height: 1;
    margin-bottom: 30px;

    span {
      display: inline-block;
      padding-bottom: 8px;
      margin-right: 4px;
    }
    a {
      margin-right: 4px;
    }
  }

  .btn.float-end {
    margin-bottom: 20px;
    margin-left: 20px;

    &:last-child {
      margin-left: 0;
    }
  }

  .password-reset {
    .row {
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }

  @media (max-width: 1023px) {
    .btn.float-end {
      margin-bottom: 0;
      margin-top: 20px;
    }
  }
}

.default-search-container {
  div.row {
    margin-top: 20px;
    margin-bottom: 20px;

    label {
      margin-right: 35px;
    }
  }
}

mat-form-field {
  width: 100%;
  &::ng-deep {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
    .mat-form-field-underline {
      display: none;
    }
  }
}

.field-label {
  width: 35%;
  label {
    font-weight: 300;
  }
}
.field-value {
  width: 65%;
  text-align: left;
  font-weight: normal;
}

.form-field-errors {
  margin-left: 30%;
  display: block;
}

