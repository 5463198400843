.ok-green-image-active {
    @include imageLexnow($ok-green-image-active);
    width:24px;
    height:24px;
    float:left;
    margin-right: 5px;
}
.login-connect-icon {
    @include imageLexnow($login-connect-icon);
    width:24px;
    height:24px;
    float:left;
    margin-right: 5px;
}
.login-mail-send-icon {
    @include imageLexnow($login-mail-send-icon);
    width:24px;
    height:24px;
    float:left;
    margin-right: 5px;
}
.login-mail-icon {
    @include imageLexnow($login-mail-icon);
    position: absolute;
    padding: 0px 12px;
    top: 8px;
    left: 22px;
}
.login-user-icon {
    @include imageLexnow($login-user-icon);
    position: absolute;
    padding: 0px 12px;
    top: 8px;
    left: 22px;
}
.login-password-icon {
    @include imageLexnow($login-password-icon);
    position: absolute;
    padding: 0px 12px;
    top: 8px;
    left: 22px;
}
.login-subscribe-icon {
    @include imageLexnow($login-subscribe-icon);
    width: 24px;
    height:24px;
    float:left;
    margin-right: 5px;
}
.login-reconnect-icon {
    @include imageLexnow($login-reconnect-icon);
    width:24px;
    height:24px;
    float:left;
    margin-right: 5px;
}
.login-logout-icon {
    @include imageLexnow($login-logout-icon);
    width:24px;
    height:24px;
    float:left;
    margin-right: 5px;
}
.info-big-image {
    @include imageLexnow($info-big-image);
    width:50px;
    height:50px;
}
.info-small-image {
    @include imageLexnow($info-small-image);
    width:24px;
    height:24px;
}
.save-medium-image-disabled {
    @include imageLexnow($save-medium-image-disabled);
    width:24px;
    height:24px;
    float:left;
    margin-right: 5px;
}
.save-medium-image-active {
    @include imageLexnow($save-medium-image-active);
    width:24px;
    height:24px;
    float:left;
    margin-right: 5px;
}
.subscribe-image-active {
    @include imageLexnow($subscribe-image-active);
    width:24px;
    height:24px;
}

.modal {
  display: block;
}

.modal-backdrop {
    background-color: $gray-bg;
    &.in {
        opacity: .5 !important;
    }
}

#modifyIndex {
    height: 20px;
    width: 20px;
}

.datepicker {
    padding: 4px 0;
}


/* style icon */
.inner-addon .fa {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}

/* align icon */
.left-addon .fa   {
    left:  0;
}
.right-addon .fa {
    right: 15px;
}

/* add padding  */
.left-addon input  { padding-left:  30px; }
.right-addon input { padding-right: 30px; }

.inner-menu {
    width:100%;
}

.notification.modal, .calculatorMessage.modal  {
    display: none;
}

.calculatorMessage {
    .modal-body {
        padding: 0 0 20px 0 !important;
        min-height: 70px;
    }

    .modal-footer {
        padding: 10px;
    }
}

  .modal-dialog {
    @media (max-width: 630px) {
      .modal-content {
        padding: 30px !important;
      }
    }
  }

#printJS-Modal {
  color: $gold !important;
}
