
$font-family: "sofia-pro", sans-serif;
$normal-font-weight: 400;
$bold-font-weight: 600;

$extra-small-text: 12px;
$smaller-text: 13px;
$small-text: 14px;
$normal-text: 15px;
$large-text: 16px;
$larger-text: 20px;
$extra-large-text: 24px;

$headermenu-text: 14px;
$footer-text: 14px;
$asterisk-size: 12px;

$fs-medium: 15px;
$fs-small: 14px;
$fs-smaller: 13px;
$fs-smallest: 12px;
$fs-big: 16px;
$fs-bigger: 20px;
$fs-biggest: 24px;
$fs-huge: 42px;

$fw-regular: 300;
$fw-medium: 500;
$fw-bold: 600;
$fw-tiny: 100;
