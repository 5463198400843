.lexnow.header {
  h4 {
    .magazineName {
      font-style: italic;
      text-decoration: underline;
      font-size: 22px;
      margin-left: 30px;
    }
  }

}

.adminMagazine {
  padding-top: 0 !important;
  height: auto;

  .magazine {
    @media(min-width: 430px) {
      padding-right: 160px;
    }
  }

  .dropdown {
    .btn {
      border: none;
      background: transparent;
      color: $gold;

      &:hover, &:active {
        border: none;
        background: transparent;
        color: $gold-dark;
      }
    }
  }

  .filter-panel {
    margin-top: 30px;

    label {
      line-height: 35px;
    }

    .btn:last-child {
      margin-right: 20px;
    }
  }

  table {
    .book-collections:hover {
      cursor: pointer;
    }
  }

  .bottomDiv {
    height: 50px;

    .btn {
      margin-left: 20px;
      margin-right: 0;
    }
  }
}
