.adminStat p {
  margin: 50px;
  font-size: large;
}

.adminStat .subtitle {
  margin: 10px, 10px, 10px, 0;
}

.adminStat .filter-panel {
  margin-top: 20px;

  label {
    line-height: 35px;
  }

  .btn:last-child {
    margin-right: 20px;
  }

  .col-label {
    padding-right: 0;
    padding-left: 0;
  }

  .col-date {
    padding-right: 20px;
    padding-left: 0;
  }

  .col-label:nth-child(3) {
    padding-left: 40px;
  }
}

.adminStat table th {
  cursor: pointer;

  .fa-arrow-down {
    transform: rotate(0deg);
    transition: transform .1s linear;
  }

  .fa-arrow-down.open {
    transform: rotate(180deg);
    transition: transform .1s linear;
  }
}

.adminStat table th, .adminStat table td {
  width: 20%;
  text-align: center;
}

.adminStat table th:first-child, .adminStat table td:first-child {
  width: 5% !important;
}

.adminStat table th:nth-child(4), .adminStat table td:nth-child(4) {
  width: 20%;
}

.adminStat table th:last-child, .adminStat table td:last-child {
  padding-top: 10px !important;
}

.adminStat table th:nth-of-type(2), .adminStat table td:nth-of-type(2) {
  width: 30%;
  text-align: start;
}

.adminStat table.connections th, .adminStat table.connections td {
  width: 20%;
}

.adminStat table.connections th:first-child, .adminStat table.connections td:first-child {
  width: 5% !important;
}

.adminStat .bottomDiv {
  height: 50px;
}

.adminStat .bottomDiv .btn {
  margin-left: 20px;
  margin-right: 0;
}
