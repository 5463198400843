
/*
 * Notes de bas de page
 * =============================================================================
 */
.progilex-v1 {

  .footnotes > table{
    border-top: 1px solid $gray-lighter;
    margin-bottom: 10px;
    color: #000000;

    tbody > tr > td,
    tbody > tr > td > span,
    tbody > tr > td > span > sup,
    tbody > tr > td > span > a {
      font-size: 12px;
      vertical-align: text-top;
    }
  }

  .footnote tr > td:first-child {
    min-width: 25px;
    vertical-align: top;
  }

  .custom-footnote-index {
    display: inline;
    vertical-align: super;
  }

  .custom-footnote-index,
  .custom-table-footnote-index {
    color: $blue-darker;
    text-decoration: none;
    font-size: 13px;
  }

  .custom-table-footnote {
    .custom-table-tfoot-footnote {
      .custom-table-footnote-rank, .custom-table-footnote-description, .custom-table-footnote-rank p, .custom-table-footnote-description p {
        font-size: 12px;
      }
    }
  }

}