.adminCode-details {
    padding-top: 0 !important;
    height: auto;
  
    table {
      width: 100%;
      border-spacing: 0;
      border-radius: 10px;
      border: none;
      box-shadow: 0 2px 2px rgba(30, 48, 81, 0.06);
      background-color: #ffffff;
      margin-bottom: 30px;
      input[type='checkbox'] {
        top: 0px;
      }
  
      thead {
        display: block;
        height: 40px;
        line-height: 40px;
  
        tr {
          display: inline-block;
          width: 100%;
          th {
            text-transform: uppercase;
            color: $gold;
            font-size: $fs-big;
          }
        }
      }
  
      tbody {
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 340px;
        margin-bottom: 5px;
  
        tr:first-child {
          margin-top: 10px;
        }
  
        tr {
          display: block;
          height: 40px;
        }
      }
  
      th:first-child, td:first-child {
        width: 10%;
        text-align: left;
        padding-left: 20px;
      }
      td {
        padding-top: 10px;
      }
      th, td {
        width: 40%;
        float: left;
        text-align: center;
      }
      th:last-child, td:last-child {
        width: 50%;
      }
    }
    .btn.pull-right {
      margin-left: 20px;
      margin-right: 0;
    }
  }