.pagination-menu {
    text-align:center;

    .navigation {
        margin: 0;
        display: inline-block;
        padding-left: 0;

        .pagination {
            margin: 0;
            background-color: $transparent;
            .page-item > span {
                background-color: transparent;
                border: none;
                color: $blue;
                padding: 0 5px;
                height: 28px;
                line-height: 28px;
            }
            .marginPagination{
                margin: 0;
                padding: 0;
                width: 52px;
                height: 28px;
                border: 1px solid $gray-lighter;
                border-radius: 14px;
                float: left;
                text-align: center;
                outline:none;
                color: $blue;
                &:focus {
                    outline:none;
                    border-color: $gray-light;
                }
                -moz-appearance:textfield;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
            .page-link {
                background-color: transparent;
                border: none;
                color: $blue;
                padding: 0 5px;
                height: 28px;
                line-height: 28px;
                svg * {
                    stroke: $gray-light;
                }
            }
        }
    }
    .dropdown{
        line-height:36px;
    }
    .dropdown-menu{
        min-width:80px;
    }
}

@media (max-width:345px) {
    .pagination-menu .navigation .pagination .page-link {
        padding: 0;
    }
}
