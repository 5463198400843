@import 'src/assets/scss/modules/colors';

@mixin imageLexnow-firefox($url){
    content: url(#{$url});
    float:left;
    margin-right: 5px;
}
@mixin imageLexnow($url){
    background-image: url(#{$url});
    background-repeat: no-repeat;
    background-position-y: center;
    float:left;
    margin-right: 5px;
}

@mixin heightBox( $heightHeader, $heightFooter, $other ){
    height: -webkit-calc(100% - #{$heightHeader} - #{$heightFooter} - #{$other});
    height:    -moz-calc(100% - #{$heightHeader} - #{$heightFooter} - #{$other});
    height:         calc(100% - #{$heightHeader} - #{$heightFooter} - #{$other});
}

@mixin contactBox( ){
    height: -webkit-calc(100% - 57px) !important;
    height:    -moz-calc(100% - 57px) !important;
    height:         calc(100% - 57px) !important;
}

@mixin navBar-title() {
    border-radius: 0;
    font-weight: $fw-bold;
    background-color: transparent !important;
    .text {
        font-weight: $fw-bold;
    }
    /*span {
    color: $light-gray;
    font-size: $small-text;
    }*/
}
@mixin navBar-inactive() {
    font-weight: $fw-regular;
    position: relative;
    padding: 7px 20px;
    &.hasicon {
        padding-left: 13px;
    }
}
@mixin navBar-active() {
    font-weight: $fw-regular;
}

@mixin small-screen() {
    @media only screen and (max-width: 1279px) {
        @content;
    }
}

@mixin medium-screen() {
    @media only screen and (min-width: 1280px) {
        @content;
    }
}

@mixin large-screen() {
    @media only screen and (min-width: 1441px) {
        @content;
    }
}

@mixin larger-screen() {
    @media only screen and (min-width: 1880px) {
        @content;
    }
}

@mixin clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin angular-material-tree-helpers() {
  .tree-invisible {
    display: none;
  }

  .tree {
    background-color: transparent;
    ul, li {
      margin-top: 0;
      margin-bottom: 0;
      list-style-type: none;
    }
    .mat-icon-rtl-mirror {
      font-size: 24px;
    }
    .mat-nested-tree-node, .mat-tree-node {
      a {
        &.active, &.active span {
          color: $blue-darker;
          font-weight: 500;
        }
        &:before {
          height: 2px;
          background-color: $gray-lighter;
        }
        &:after {
          height: 2px;
          background-color: $blue-darker;
        }
        &:hover {
          &:after {
            background-color: $blue-darker;
          }
        }
      }
      a, a span {
        line-height: 1.3;
        color: $blue;
        font-weight: normal;
        text-align: left;
      }
      a span {
        font-size: 13px;
      }
      &.active {
        a, a span {
          color: $blue-darker;
        }
      }
      div[role=group] {
        padding-left: 30px;
      }
    }
    div[role=group] > .mat-tree-node {
      padding-left: 30px;
    }
  }
}
