/*
   * JUGEMENT
   * =============================================================================
   */
.intro-judgment {
  margin-bottom: 15px;
  text-align: center;
  font-weight: bold;
  font-style: italic;
  color: $blue-darker;
}

.progilex-v1 .act {
  display: block;
}
.progilex-v1 .intitule {
  display: block;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: $blue-darker;
  padding-top: 10px;
  padding-bottom: 25px;
  margin: 0;
}
.progilex-v1 .introduction {
  margin: 2px;
  display: block;
  font-size: 14px;
}
.progilex-v1 .consid {
  padding-top: 5px;
  font-style: normal;
}

/*
 * JUGEMENT
 * =============================================================================
 */
.progilex-v1 .judgment {
  display: block;
  margin: 15px;
}
.progilex-v1 .judgment .alinea {
  display: block;
  margin: 0 0 5px 0;
}
.progilex-v1 .intro-judgment {
  margin-bottom: 15px;
  text-align: center;
  font-weight: bold;
  font-style: italic;
  color: $blue-darker;
}

.progilex-v1 .title-proj {
  display: block;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: $blue-darker;
  padding-bottom: 5px;
}
.progilex-v1 .intro-proj {
  display: block;
  font-size: 13px;
  font-style: italic;
  font-weight: bold;
  text-align: left;
  color: $blue-darker;
  padding-bottom: 5px;
}
.progilex-v1 .title-doc {
  display: block;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  color: $blue-darker;
  padding-top: 15px;
  padding-bottom: 5px;
}
.progilex-v1 .title-part {
  display: block;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  color: $blue-darker;
}
.progilex-v1 .date-doc {
  display: block;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  padding-bottom: 5px;
}
.progilex-v1 a.link-act:link, a.link-act:visited {
  text-decoration: underline;
  color: $blue-darker;
}
.progilex-v1 a.link-act:hover {
  color: $blue-darker;
  font-weight: bold;
}