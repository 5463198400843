@import "../modules/colors";

.app-breadcrumbs {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .app-breadcrumb-icon {
    margin-right: 20px;
    padding: 12px;
    border: 1px solid $blue-darkest;
    border-radius: 50%;
    color: white;
    background-color: $blue-darkest;
    mat-icon {
      width: 18px;
      height: 18px;
      font-size: 18px;
    }
  }
  .app-breadcrumb-content {
    display: flex;
    align-items: center;
    font-size: 14px;
    .app-breadcrumb {
      margin-right: 20px;
      position: relative;
      &::after {
        width: 30px;
        background-color: $blue-darkest;
        height: 1px;
        content: "";
        display: block;
        right: -26px;
        position: absolute;
        transform: rotate(90deg) translateY(-50%);
        top: 50%;
      }
      &:nth-last-child(1)::after {
        display: none;
      }
    }
    h4 {
      font-size: 18px !important;
    }
    h5 {
      margin-bottom: 0px;
      font-weight: 300 !important;
      font-size: 18px !important;
    }
  }
}
