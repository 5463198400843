    /* Navigation */

    $menu-icon-url: "/../images/menu/registry.svg";
    $account-icon-url: "/../images/menu/account_medium.svg";
    $calculator-icon-url: "/../images/menu/calculator_medium.svg";
    $administration-icon-url: "/../images/menu/administration_medium.svg";
    $information-icon-url: "/../images/menu/information_medium.svg";
    $login-icon-url: "/../images/menu/login_medium.svg";
    $logout-icon-url: "/../images/menu/logout_medium.svg";
    $alerts-icon-url: "/../images/menu/sign_warning.svg";

    /* Misc */
    $warning-image-url: "/../images/normal/sign_warning.png";
    $cancel-red-image-active: "/../images/normal/cancel_red.svg";
    $ok-green-image-active: "/../images/normal/ok_green_16.svg";
    $error-red-image: "/../images/normal/error_red_48.svg";
    $info-big-image: "/../images/grey/info_big.svg";
    $info-small-image: "/../images/white/info_small.svg";

    /* Footer */

    $facebook-icon-url: "/../images/menu/facebook_medium.svg";
    $linkedin-icon-url: "/../images/menu/linkedin_medium.svg";
    $twitter-icon-url: "/../images/menu/twitter_medium.svg";
    $mail-icon-url: "/../images/menu/mail_medium.svg";

    /* Authentication */

    $login-connect-icon: "/../images/authentication/login_green.svg";
    $login-mail-send-icon: "/../images/authentication/mail_send_green.svg";
    $login-mail-icon: "/../images/authentication/mail_grey.svg";
    $login-user-icon: "/../images/authentication/user_grey.svg";
    $login-password-icon: "/../images/authentication/password_grey.svg";
    $login-subscribe-icon: "/../images/authentication/register_green_16.svg";
    $login-reconnect-icon: "/../images/authentication/refresh_green_grey_24.svg";
    $login-logout-icon: "/../images/authentication/logout_grey_red_24.svg";

    /* Documents & Folders */
    $document-text-error-warning-medium: "/../images/normal/document_text_warning_16.svg";
    $document-text-error-ok-medium: "/../images/normal/document_text_ok_16.svg";
    $document-text-error-error-medium: "/../images/normal/document_text_error_16.svg";
    $document-scroll-up: "/../images/grey/scroll_up_medium.svg";

    /* Cruds buttons */

    $add-image-disabled: "/../images/grey/plus.svg";
    $delete-image-disabled: "/../images/grey/delete.svg";
    $edit-image-disabled: "/../images/grey/edit.svg";
    $save-image-disabled: "/../images/grey/save_small.svg";
    $save-medium-image-disabled: "/../images/grey/save_medium.svg";
    $search-image-disabled: "/../images/grey/search.svg";

    $add-image-active: "/../images/white/plus.svg";
    $delete-image-active: "/../images/white/delete.svg";
    $edit-image-active: "/../images/white/edit.svg";
    $save-image-active: "/../images/white/save_small.svg";
    $save-medium-image-active: "/../images/white/save_medium.svg";
    $search-image-active: "/../images/white/search.svg";
    $search-history-image: "/../images/white/search_history.svg";

    /* Buttons disabled */

    $arrow-back-image-disabled: "/../images/grey/arrow_back_small.svg";
    $arrow-back-medium-image-disabled: "/../images/grey/arrow_back_medium.svg";
    $arrow-left-image-disabled: "/../images/grey/arrow_left.svg";
    $calculate-image-disabled: "/../images/grey/equal.svg";
    $document-image-disabled: "/../images/grey/document.svg";
    $document-error-image-disabled: "/../images/grey/document_error.svg";
    $folder-image-disabled: "/../images/grey/folder.svg";
    $folder-document-image-disabled: "/../images/grey/folder_document.svg";
    $folder-open-image-disabled: "/../images/grey/folder_open.svg";
    $forbidden-image-disabled: "/../images/grey/sign_forbidden.svg";
    $minus-image-disabled: "/../images/grey/minus.svg";
    $move-to-first-image: "/../images/grey/navigate_first_page_small.svg";
    $move-to-last-image: "/../images/grey/navigate_last_page_small.svg";
    $navigate-next-image: "/../images/grey/navigate_next_page_small.svg";
    $navigate-previous-image: "/../images/grey/navigate_previous_page_small.svg";
    $navigate-left-image: "/../images/grey/navigate_left.svg";
    $navigate-right-image: "/../images/grey/navigate_right.svg";
    $ok-image-disabled: "/../images/grey/ok.svg";
    $play-image-disabled: "/../images/grey/media_play.svg";
    $plus-image-disabled: "/../images/grey/plus.svg";
    $question-image-disabled: "/../images/grey/question_small.svg";
    $send-image-disabled: "/../images/grey/mail_medium.svg";
    $thumb-down-image-disabled: "/../images/grey/hand_thumb_down.svg";
    $thumb-up-image-disabled: "/../images/grey/hand_thumb_up.svg";

    /* Buttons active */

    $alert-image-active: "/../images/white/alert_small.svg";
    $arrow-back-image: "/../images/white/arrow_back_medium.svg";
    $arrow-left-image-active: "/../images/white/arrow_left.svg";
    $calculate-image-active: "/../images/white/equal.svg";
    $compare-image-active: "/../images/white/equal.svg";
    $document-image-active: "/../images/white/document_text.svg";
    $document-exchange-image-active: "/../images/white/document_exchange_small.svg";
    $download-pdf-image-active: "/../images/white/download_PDF_medium.svg";
    $error-image-active: "/../images/white/error.svg";
    $folder-image-active: "/../images/white/folder.svg";
    $folder-add-image-active: "/../images/white/folder_add_small.svg";
    $folder-add-medium-image-active: "/../images/white/folder_add_medium.svg";
    $folder-document-image-active: "/../images/white/folder_document.svg";
    $folder-open-image-active: "/../images/white/folder_open.svg";
    $forbidden-image-active: "/../images/white/sign_forbidden.svg";
    $mail-edit-image-active: "/../images/white/mail_edit_small.svg";
    $minus-image-active: "/../images/white/minus.svg";
    $navigate-left-image-active: "/../images/white/navigate_left.svg";
    $navigate-left2-image-active: "/../images/white/navigate_left2.svg";
    $navigate-right-image-active: "/../images/white/navigate_right.svg";
    $navigate-right2-image-active: "/../images/white/navigate_right2.svg";
    $update-image-active: "../image/white/refresh_white_16.svg";
    $ok-image-active: "/../images/white/ok.svg";
    $play-image-active: "/../images/white/play_small.svg";
    $play-big-image-active: "/../images/white/play_big.svg";
    $plus-image-active: "/../images/white/plus_small.svg";
    $print-image-active: "/../images/white/print_small.svg";
    $print-medium-image-active: "/../images/white/print_medium.svg";
    $question-image-active: "/../images/white/question.svg";
    $reset-image-active: "/../images/white/reset_small.svg";
    $refresh-image-active: "/../images/white/refresh_white_16.svg";
    $send-image-active: "/../images/white/mail_medium.svg";
    $subscribe-image-active: "/../images/white/mail_edit_small.svg";

    /* section buttons*/
    $plus-image-light-gray: "/../images/lightgray/plus.svg";
    $minus-image-light-gray: "/../images/lightgray/minus.svg";

    /** CheckBox **/

    $checkbox-unchecked: "/../images/grey/checkbox_unchecked_small.svg";
    $checkbox-checked: "/../images/grey/checkbox_checked_small.svg";
    $radiobutton-unticked: "/../images/grey/radio_button_unticked_small.svg";
    $radiobutton-ticked: "/../images/grey/radio_button_ticked_small.svg";
