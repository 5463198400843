.filter-panel {
    padding-top: 10px;
    .btn {
         &:last-child {
            margin-right: 0;
            margin-left: 0;
        }
    }
}

.rapid-search {
    float: right;
    cursor: pointer;
    padding-top: 10px;
    @media (max-width:420px) {
        float: none;
        a:before {
            //content: "< ";
        }
    }
}
