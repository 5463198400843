.adminBook {

  .filter-panel {
    margin-top: 30px;

    label {
      line-height: 35px;
    }

    .btn:last-child {
      margin-right: 20px;
    }
  }

  .topcommands-panel {
    @include clearfix;

    > .btn {
      float: left;
      margin-right: 20px;
      margin-top: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: 340px) {
      > .btn {
        clear: both;
        margin-top: 10px;
      }
    }
  }

  table {

    th, td {
      width: 9%;
    }

    th:first-child, td:first-child {
      width: 5% !important;
    }

    th:last-child, td:last-child {
      padding-top: 10px !important;
      width: 6%;
    }

    th:nth-of-type(2), td:nth-of-type(2) {
      width: 35%;
    }

    th:nth-of-type(3), td:nth-of-type(3) {
      width: 27%;
    }
  }

  .bottomDiv {
    height: 50px;

    .btn {
      margin-left: 20px;
      margin-right: 0;
    }
  }

  form {
    .first-column {
      border-right: 1px solid $gray-lighter;
    }

    .book-thumbnail {
      margin-top: 10px;
      height: 50px;
      max-width: 100%;
      object-fit: contain;
    }
  }
}

.book-price {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.admin-book-metadata {
  margin: 15px;
}

.adminBookPart {
  table {
    margin-top: 0 !important;
    background-color: transparent;
    box-shadow: none !important;

    thead {
      border-bottom: 1px solid $gray-lighter;

    }

    tbody {
      tr:first-child {
        margin-top: 20px !important;
      }
    }

    th:first-child, td:first-child {
      width: 5% !important;
    }

    th:nth-of-type(2), td:nth-of-type(2) {
      width: 8%;
      text-align: center;
    }

    th:nth-of-type(3), td:nth-of-type(3) {
      width: 50%;
    }

    th:nth-of-type(4), td:nth-of-type(4) {
      width: 10%;
    }

    th:nth-of-type(5), td:nth-of-type(5) {
      width: 10%;
    }

    th:last-child, td:last-child {
      padding-top: 10px !important;
      width: 17%;
      text-align: center;
    }
  }

  .bottomDiv {
    height: 50px;

    .btn {
      margin-left: 20px;
      margin-right: 0;
    }
  }
}

.admin-book-parts {
  margin: 15px;
}

.admin-book-menu .list-group-item {
  margin: 20px 0 20px 0;
}

.table-container {
  overflow-x: auto;
  table {
    min-width: 700px;
  }
}

div.col-sm-8.datepicker {
  width: 100%;
}

.text-overflow-dynamic-container {
  position: relative;
  max-width: 100%;
  padding: 0 !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  vertical-align: text-bottom !important;
}

.text-overflow-dynamic-ellipsis {
  position: absolute;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  top: 0;
  left: 0;
}

.text-overflow-dynamic-container:after,
.text-overflow-dynamic-ellipsis:after {
  content: '-';
  display: inline;
  visibility: hidden;
  width: 0;
}
