
/************ IMAGES *****************/
/* Non-IE browsers */
.save-image-disabled {
  @include imageLexnow($save-image-disabled);
  width: 16px;
  height: auto;
}
.save-image-active {
  @include imageLexnow($save-image-active);
  width: 16px;
  height: auto;
}
.search-image-disabled {
  @include imageLexnow($search-image-disabled);
  width:24px;
  height:24px;
}
.add-image-disabled {
  @include imageLexnow($add-image-disabled);
  width: 16px;
  height: auto;
}
.add-image-active {
  @include imageLexnow($add-image-active);
  width: 16px;
  height: auto;
}
.delete-image-disabled {
  @include imageLexnow($delete-image-disabled);
  width: 16px;
  height: auto;
}
.delete-image-active {
  @include imageLexnow($delete-image-active);
  width: 16px;
  height: auto;
}
.edit-image-disabled {
  @include imageLexnow($edit-image-disabled);
  width: 16px;
  height: auto;
}
.edit-image-active {
  @include imageLexnow($edit-image-active);
  width: 16px;
  height: auto;
}
.question-image-disabled {
  @include imageLexnow($question-image-disabled);
  width: 16px;
  height: auto;
  float:right;
  margin-left: 5px;
}
.calculate-image-disabled {
  @include imageLexnow($calculate-image-disabled);
  width: 16px;
  height: auto;
}
.calculate-image-active {
  @include imageLexnow($calculate-image-active);
  width: 16px;
  height: auto;
}
.ok-green-image-active {
  @include imageLexnow($ok-green-image-active);
  width:24px;
  height:24px;
}
.cancel-red-image-active {
  @include imageLexnow($cancel-red-image-active);
  width:24px;
  height:24px;
}
.error-red-image {
  @include imageLexnow($error-red-image);
  width:60px;
  height:50px;
  background-position-x: 10px;
  float: none;
}
.arrow-back-image-disabled {
  @include imageLexnow($arrow-back-image-disabled);
  width:24px;
  height:24px;
}
.arrow-back-image-active {
  @include imageLexnow($arrow-back-image);
  width:24px;
  height:24px;
}
.play-image-disabled {
  @include imageLexnow($play-image-disabled);
  width: 24px;
  height:24px;
}
.document-scroll-up {
  @include imageLexnow($document-scroll-up);
  width: 24px;
  height: auto;
}
.search-history-image {
  @include imageLexnow($search-history-image);
  width:24px;
  height:24px;
}
.save-image-disabled {
  @include imageLexnow($save-image-disabled);
  width: 16px;
  height: auto;
}
.move-to-first-image {
  @include imageLexnow($move-to-first-image);
  width:16px;
  height:16px;
  margin-top:10px;
}
.move-to-last-image {
  @include imageLexnow($move-to-last-image);
  width:16px;
  height:16px;
  margin-top:10px;
}
.navigate-next-image {
  @include imageLexnow($navigate-next-image);
  width:16px;
  height:16px;
  margin-top:10px;
}
.navigate-previous-image {
  @include imageLexnow($navigate-previous-image);
  width:16px;
  height:16px;
  margin-top:10px;
}