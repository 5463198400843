@import "src/assets/scss/modules/colors";

.document-warning-img {
    @include imageLexnow($document-text-error-warning-medium);
    height: 24px;
    width:24px;
}
.document-ok-img{
    @include imageLexnow($document-text-error-ok-medium);
    height: 24px;
    width:24px;
}

.document-forbidden {
    background-color: $light-gray;
}
.lx-folder-list-padding{
    @media(min-width:430px) {
        padding-right: 160px;
    }
}
.folder-container {
    .sub-section-title {
        padding: 15px 0 11px;
        border-radius: 4px;
        margin-right: 0;
        margin-left: 0;
        color: $blue-darker;
        font-size: $fs-big;
        font-weight: $fw-bold;
        border-top: none;
        background: white;
        margin-top: 20px;
        input[type=checkbox] {
            float: left;
            margin-right: 10px;
            margin-top: 4px;
            margin-left: 0;
        }
        .collapse-button {
            margin-top: -3px;
        }
    }

    .sub-section-sub-title {
        padding: 20px 0;
        margin-right: 0;
        margin-left: 0;
        border-top: 1px solid $gray-lighter;
    }
    .sub-section-title input {
        margin-left:2px;
    }
    .sub-section-container {
        background: white;
        margin-right: 0;
        margin-left: 0;
        padding: 20px 0;
        height: 58px;
        input[type=checkbox] {
            float: left;
        }
        @media (max-width:767px) {
            height: auto;
            > div {
                clear: both;
                margin-bottom: 10px;
                &:last-child {
                    clear: none;
                }
                input {
                    float: none !important;
                }
                .btn-true, .btn-false {
                    float: right;
                }
                .link {
                    float: left !important;
                    margin-left: 0;
                    margin-top: -10px;
                }
            }
        }
    }
    .sub-section-container input{
        margin-right:15px;
    }
    .ellipsis {
        height: 16px;
    }
    .btn-true, .btn-false {
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        margin-top: -6px;
        svg {
            width: 30px;
            height: 30px;
        }
    }
    .btn-false {
        background-color: #f5e4e0;
        svg * {
            stroke: #dd3f19;
        }
    }
    .btn-true {
        background-color: #eaf6f1;
        svg * {
            stroke: #38a279;
        }
    }
    .btn-icon-single {
        margin-left: 10px;
        margin-top: -11px;
    }
    @media (max-width:767px) {
        .section-title {
            display: none;
        }
    }
  .folder-content {
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
  .publication-conversation-bot-container {
    transform: translateX(100vw);
    opacity: 0;
    width: 0;
    transition: all 0.3s ease-in-out;
    position: absolute;
    left: 0;
    right: 0;
    margin: 50px auto;
    .input-container {
      width: 100% !important;
      min-width: unset !important;
    }
    &::ng-deep {
      .input-container {
        width: 100% !important;
      }
    }
  }
  .panel-button-footer {
    transition: all 0.3s ease-in-out;
  }
  &.conversation-mode {
    .folder-content {
      opacity: 0;
      transform: translateX(-100vw);
    }
    .publication-conversation-bot-container {
      width: calc(100% - 120px);
      opacity: 1;
      transform: translateX(0);
      max-width: 1000px;
    }
    .panel-button-footer {
      right: 50%;
      border-right: 1px solid $gray-lighter
    }
  }
}

.search-list {
    .sub-section-container {
        height: auto;
        line-height: 24px;
    }
    .btn-icon-single {
        margin-top: 0;
    }
}

.link.marginLeft {
    margin-left: 10px;
}
