.advancedSearch {
    h3 {
        color: $gold;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        margin-top: 40px;
        margin-bottom: 20px;
    }
    .document-types-list {
        list-style-type: none;
        border-bottom: 1px solid $gray-lighter;
        padding-bottom: 40px;
        padding-left: 0;
        li {
            display: inline-block;
            margin-right: 30px;
            input[type='checkbox'] {
                top: 3px;
            }
            &:last-child {
                margin-right:0;
            }
        }
        @media (max-width: 1215px) {
            li {
                margin-right: 0;
                width: 33%;
                padding: 0 0 15px 15px;
            }
        }
        @media (max-width: 992px) {
            li {
                width: 50%;
            }
        }
        @media (max-width: 490px) {
            li {
                width: auto;
                display: block;
            }
        }
    }
    .document-type-bar {
        cursor: pointer;
        background-color: $white;
        border-radius: 3px;
        padding: 15px 0;
        width: 100%;
        box-shadow: 0 2px 2px $gray-shadow;
        .document-type-bar-table {
            display: table;
            width: 100%;
        }
        .document-type-bar-row {
            display: table-row;
        }
        .input-group {
            display: table-cell;
            width: calc(100% / 6);
            vertical-align: middle;
            border-right: 1px solid $gray-lighter;
            height: 30px;
            background: transparent;
            border-radius: 0;
            color: $blue;
            text-align: center;
            text-transform: uppercase;
            font-weight: $fw-bold;
            font-size: $fs-smallest;
            position: relative;
            transition: color $trans;
            padding: 0 25px;
            &:last-child {
                border-right: none;
            }
            &:hover {
                color: $blue-dark;
                .document-type-multiple{
                    display: block;
                }
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: -15px;
                height: 0px;
                width: 100%;
                left: 0;
                background-color: $gold;
                transition: all $trans;
            }
            &.active {
                color: $gold;
                &:before {
                    height: 3px;
                }
                &:hover {
                    color: $gold-dark;
                    &:before {
                        background-color: $gold-dark;
                    }
                }
            }
            .document-type-multiple{
                position: relative;
                display: none;
                z-index: 1000;
                text-align: left;
                .document-type-checkboxes{
                    position: absolute;
                    cursor: pointer;
                    background-color: $white;
                    border-radius: 3px;
                    padding: 15px 15px;
                    width: 100%;
                    box-shadow: 0 2px 2px $gray-shadow;
                }
            }
        }
    }

    .form-group {
        margin-bottom: 20px;
        .datepicker.input-group {
            .form-control {
                border-radius: 3px !important;
            }
            .input-group-addon {
                display: none;
            }
        }
        &.actions {
            padding-top: 20px;
            .btn {
                margin-left: 30px;
            }
        }
    }
}
