.adminMagazineSubscription {
    padding-top: 0 !important;
    height: auto;

    .dropdown {
      .btn {
        border: none;
        background: transparent;
        color: $gold;
        &:hover, &:active {
          border: none;
          background: transparent;
          color: $gold-dark;
        }
      }
    }

    .btn-group-vertical{
      width: 20px !important;
      height: 30px !important;
      padding: 0 !important;
      margin-top: 7px;
      .btn{
        border: none;
        background: transparent;
        color: $gold;
        border-radius: 0 !important;
        width: 20px !important;
        height: 10px !important;
        padding: 0 !important;
        margin: 0 !important;
      }
      &:hover, &:active {
        border: none;
        background: transparent;
        color: $gold-dark;
      }
    }



    .btn-group-vertical{
      width: 20px !important;
      height: 30px !important;
      padding: 0 !important;
      margin-top: 7px;
      .btn{
        border: none;
        background: transparent;
        color: $gold;
        border-radius: 0 !important;
        width: 20px !important;
        height: 10px !important;
        padding: 0 !important;
        margin: 0 !important;
      }
      &:hover, &:active {
        border: none;
        background: transparent;
        color: $gold-dark;
      }
    }

    .filter-panel {
      margin-top: 30px;
      label {
        line-height: 35px;
      }
      .btn:last-child {
        margin-right: 20px;
      }
    }

    table {
      th, td {
        width: 26%;
      }
      th:first-child, td:first-child {
        width: 2%;
      }
      th:first-of-type, td:first-of-type {
        width:20%;
      }
      th:nth-of-type(2), td:nth-of-type(2) {
        width:30%;
      }
      th:last-child, td:last-child {
        padding-top: 10px !important;
        width:30%;
      }
      .book-subscriptions:hover {
        cursor: pointer;
      }
    }
  .topcommands-panel {
    @include clearfix;

    > .btn {
      float: left;
      margin-right: 20px;
      margin-top: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

    .bottomDiv {
      height: 50px;
      .btn {
        margin-left: 20px;
        margin-right: 0;
      }
    }
}

.magazine-subscription-price {
    padding-right: 15px !important;
    padding-left: 15px !important;
}

.admin-magazine-subscription-metadata{
  margin: 15px;
  form:first-of-type >div:first-of-type{
    border-right: 1px solid $gray-lighter;
  }
}

.adminMagazineSubscriptionPart {
  table {
    margin-top: 0 !important;
    background-color: transparent;
    box-shadow: none !important;
    thead {
      border-bottom: 1px solid $gray-lighter;

    }
    tbody {
      tr:first-child {
        margin-top: 20px !important;
      }
    }
    th, td {
      width: 35%;
    }
    th:last-child, td:last-child {
      padding-top: 10px !important;
      width:20%;
    }
  }
  .bottomDiv {
    height: 50px;
    .btn {
      margin-left: 20px;
      margin-right: 0;
    }
  }
}

.admin-magazine-subscription-parts {
  margin: 15px;
}

.admin-magazine-subscription-menu .list-group-item {
  margin : 20px 0 20px 0;
}

.adminMagazineSubscriptionLinkedCompany {
    .filter-panel {
      margin-top: 30px;
      label {
        line-height: 35px;
      }
      .btn:last-child {
        margin-right: 20px;
      }
    }

    table {
        th, td {
          width: 22%;
        }
        th:last-child, td:last-child {
         padding-top: 10px !important;
       }
       input {
         text-align: center;
       }
    }

    .bottomDiv {
      height: 50px;
      .btn {
        margin-left: 20px;
        margin-right: 0;
      }
    }
}

.adminCompanyMagazineSubscription {
  table {
    width: 100%;
    tr {
      border: 1px solid #dddddd;
      th, td {
        width: 28%;
        padding-left: 20px;
      }
      th:last-child, td:last-child {
        width:15%;
      }
    }

  }
}
