.adminPeriodic {

 .filter-panel {
  margin-top: 30px;

  label {
   line-height: 35px;
  }

  .btn:last-child {
   margin-right: 20px;
  }
 }

 table {
  th, td {
   width: 15%;
   text-align: center;
  }

  th:first-child, td:first-child {
   width: 5% !important;
  }

  th:last-child, td:last-child {
   padding-top: 10px !important;
   width: 35%;
  }

  th:nth-of-type(2), td:nth-of-type(2) {
   width: 45%;
   text-align: start;
  }
 }

 .bottomDiv {
  height: 50px;

  .btn {
   margin-left: 20px;
   margin-right: 0;
  }
 }
}
