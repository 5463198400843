
.login-icon-url {
    @include imageLexnow($login-icon-url);
    width:24px;
    height:24px;
}

/********************** HEADER **********************/
.container-header {
    top: 0;
    height: $header-height;

    .wrapper {
        height: 100%;
    }
}



/********************** LANGUAGE **********************/
.languageFlag {
    z-index: 5000;
    position: relative;
    text-align: right;
    height: $small-login-height;
    line-height: $small-login-height;
    font-size: $extra-small-text;

    .log {
        float: right;
    }
}

.languageFlag.connected {
    line-height: $small-login-height;
}

.languageFlag.log > span, .languageFlag.log > a {
    color: $dark-gray;
}
.pipe {
    margin: 0 10px 0 0;
    cursor: pointer;
}


@media only screen and (min-width : 1280px) {
    .languageFlag {
        height: $large-login-height;
        line-height: $large-login-height;
        font-size: $small-text;
    }

    .languageFlag.connected {
        line-height: $large-login-height;
    }


}