/*
 * Tables personnalisées provenant de Tiny
 * =============================================================================
 */
.progilex-v1 {
  table.custom-table {
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid #d3d3d3;
    width: 100%;

    td, th {
      padding-right: 10px;
      padding-left: 10px;
    }

    tbody td {
      border-bottom: 1px dashed #d3d3d3;
    }

    tbody td:first-child, table.custom-table th {
      border-right: 1px dashed #d3d3d3;
    }
  }
}

/*
 * Liste personnalisées
 * =============================================================================
 */
.progilex-v1 {
  .custom-list {
    display: table;
    margin-left: 28px;
    margin-right: 10px;
    max-width: 50vw;

    .custom-list {
      margin-left: 0px;
    }

    .custom-list .row .cell:last-child {
      padding-bottom: 0px;
    }

    tr {
      margin-right: -15px;
      margin-left: -15px;
    }

    tr:before, tr:after {
      display: table;
      content: " ";
    }

    .body {
      padding: 5px;
    }

    .body > .row {
      display: table-row;
      font-size: 14px;
    }

    .row .cell {
      display: table-cell;
    }

    .row .cell:first-child {
      padding-right: 15px;
      padding-left: 5px;
      vertical-align: top;
    }

    .row .cell:last-child {
      padding-bottom: 1.1em;
    }
  }
}
