
/*
 * GR-ARTICLES
 * =============================================================================
 */
.progilex-v1 {

  .gr-articles {
    padding: 5px;
  }

  .gr-articles > .ti-gr-articles {
    display: block;
    font-size: 18px;
    font-weight: $fw-medium;
    margin: 0;
  }

  .gr-articles > .gr-articles > .ti-gr-articles {
    display: block;
    font-size: 17px;
    font-weight: $fw-medium;
    margin: 0 0 5px 0;
  }

  .gr-articles > .gr-articles > .gr-articles > .ti-gr-articles,
  .gr-articles > .gr-articles > .gr-articles > .ti-gr-articles p {
    display: block;
    font-size: 17px;
    font-weight: $fw-medium;
    text-align: left;
    margin: 0 0 5px 0;
  }

  .gr-articles > .gr-articles > .gr-articles > .gr-articles > .ti-gr-articles,
  .gr-articles > .gr-articles > .gr-articles > .gr-articles > .ti-gr-articles p {
    display: block;
    font-size: 17px;
    font-weight: normal;
    text-align: left;
    margin: 0 0 5px 0;
  }

  .gr-articles > .gr-articles > .gr-articles > .gr-articles > .gr-articles > .ti-gr-articles,
  .progilex-v1 .gr-articles > .gr-articles > .gr-articles > .gr-articles > .gr-articles > .ti-gr-articles p {
    display: block;
    font-size: 17px;
    font-style: italic;
    text-align: left;
    margin: 0 0 5px 0;
  }

  .ti-gr-articles, .ti-gr-articles p {
    color: $blue;
    margin: 0;
  }

  .object {
    text-align: left;
    font-size: 15px;
    color: $blue;
  }

  .content {
    margin: 5px;
    padding-top: 5px;
    padding-bottom: 0px;
    display: block;

    .alinea {
      margin: 5px;
      padding: 0px;
      display: block;
    }
  }

  .introduction .p {
    margin: 2px;
    display: block;
    font-size: 14px;
  }
}


/*
 * ARTICLES
 * =============================================================================
 */
.progilex-v1 {

  .articles {
    font-weight: $fw-regular;
    padding: 5px;
    text-align: left;
  }

  .intro-articles {
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
    margin: 0;
  }

  .article {
    margin-top: 15px;
    margin-bottom: 15px;

    .alinea {
      margin-top: 10px;
    }
  }

  .num-article, .num-article p {
    display: inline-block;
    margin: 0 0 0 5px;
    font-weight: $fw-medium;
    font-size: 14px;
    .wrapper-article-flow-selector {
      display: inline-block;
      background-color: #eee;
      padding: 8px;
      border-radius: 50%;
      position: relative;
      top: 4px;
      .article-flow-selector {
        margin-left: 0;
      }
    }
    &.clickable {
      * {
        transition: color $trans;
      }
      &:hover {
        * {
          color: $blue-darker !important;
        }
      }
      &:after {
        content: "";
        width: 32px;
        height: 32px;
        top: 10px;
        display: inline-block;
        position: relative;
        background-image:url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20d%3D%22M11%2C22V10H21V22l-5-4Z%22%20fill%3D%22none%22%20stroke%3D%22%236D798E%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E");
      }
    }
  }

  .num-article +.alinea {
    padding-top: 0;
    text-indent: 10px;
  }

  .num-article +.parag {
    display: block;
  }
  .num-article +.modificator {
    display: inline;
  }

  .modificator, .modificator p {
    font-style: italic;
    margin: 0 0 0 5px;
  }

  .ti-article, .ti-article p {
    font-weight: 700;
    margin: 0 0 0 5px;
    text-indent: 15px;
  }

  .alinea {
    display: block;
    padding-top: 5px;
    padding-left: 10px;
  }

  .parag {
    display: block;
    padding-top: 5px;
    padding-left: 10px;

    .no-parag, .no-parag p {
      display: inline-block;
      margin: 0 5px 0 0;
      color: $blue;
      font-size: 14px;
      font-weight: $fw-medium;

      .texte {
        display: inline;
      }
    }
  }

  .parag >.alinea {
    display: inline;
    padding-top: 5px;
    padding-bottom: 5px;

  }
}
