/* pretty radio */
.lx_radio > input[type="radio"] {
  display: none;
}
.lx_radio > input[type="radio"] + *::before {
  margin: 14px 10px -4px 10px;
  width: 14px;
  height: 14px;
  border: solid 3px #DDD;
  border-radius: 12px;
  box-shadow: 0 0 0 3px #777;
  display: inline-block;
  content: "";
}
.lx_radio > input[type="radio"]:checked + * {
  color: #484848;
}
.lx_radio > input[type="radio"]:checked + *::before {
  background: #484848;
}

.radiobutton-unticked {
    @include imageLexnow($radiobutton-unticked);
    width: 16px;
    height: 16px;
}
.radiobutton-ticked {
    @include imageLexnow($radiobutton-ticked);
    width: 16px;
    height: 16px;
    background-color: $dark-gray;
}
.checkbox-checked {
    @include imageLexnow($checkbox-checked);
    width: 16px;
    height: 16px;
}
.checkbox-unchecked {
    @include imageLexnow($checkbox-unchecked);
    width: 16px;
    height: 16px;
}
.offlineBtn {
    background-color: $light-gray;
    border-color: $light-gray;
    color: $dark-gray;
    margin: 0 5px;
    font-size: $smaller-text;
}

.onlineBtn {
    background-color: $dark-gray;
    border-color: $dark-gray;
    color: $lighter-gray;
    font-size: $smaller-text;
    margin: 0 5px;

    span {
        color: $lighter-gray;
    }
}
.onlineBtn:hover {
    background-color: $dark-yellow;
    color: $lighter-gray;
    border-color: $dark-yellow;
}
.onlineBtn:focus {
    background-color: $dark-gray;
    border-color: $dark-gray;
    color: $lighter-gray;
    outline: none;
}
.collapse-button{
    font-size: $smaller-text;
    @include imageLexnow('/../images/icons/arrow-up.svg');
    background-color: transparent;
    width: 18px;
    height: 28px;
    border: none;
}
.collapse-button.collapsed {
    @include imageLexnow('/../images/icons/arrow-down.svg');
    background-color: transparent;
    width: 18px;
    height: 28px;
    border: none;
}

.btn-navDocument {
    font-size: $smaller-text;
    background-color: $light-gray;
    border-color: $dark-gray;
    color: $dark-gray;
}

.btn-navDocument:hover {
    background-color: $dark-yellow;
    border-color: $dark-gray;
    color: $dark-gray;
}

.span-navDocument.active {
   border-color: $dark-gray;
}

.span-navDocument.active > a {
   border-color: $dark-gray;
}

.span-navDocument.active > a > .btn-navDocument {
   background-color: $dark-yellow;
   border-color: $dark-gray;
   color: $dark-gray;
   outline: 0;
}

.btn-connect {
    color: $text-color;
    background-color: $light-gray;
    border: 1px solid $gray;
    font-size: $smaller-text;
}

.btn-connect:hover, .btn-connect.active {
    background-color: $yellow;
    border-color: $light-gray;
    color: $text-color;
}

.btn-connect.disabled{
    background-color: $lighter-gray;
    color: $light-gray;
    border-color: $lighter-gray;
}
.btn-connect:focus {
    outline-color: $yellow;
}

.languageFlag {
    .onlineBtn, .offlineBtn {
        font-size: $extra-small-text;
        padding: 3px 7px;
    }
}
.pdf-export-btn > div{
    @include imageLexnow($download-pdf-image-active);
    height:24px;
    width:24px;
    float:left;
    margin-right:5px;
    border: none;
    background-color: $dark-gray;

}
.pdf-export-btn:hover > div{
    @include imageLexnow($download-pdf-image-active);
    height:24px;
    width:24px;
    float:left;
    margin-right:5px;
    border: none;
    background-color: $dark-yellow;
}
.add-to-my-alerts-btn > div{
    @include imageLexnow($alert-image-active);
    border: none;
    background-color: $dark-gray;
    height:24px;
    width:24px;
    float:left;
    margin-right:5px;
}
.add-to-my-alerts-btn:hover > div{
    @include imageLexnow($alert-image-active);
    border: none;
    background-color: $dark-yellow;
    height:24px;
    width:24px;
}
.delete-btn > div{
    @include imageLexnow($delete-image-active);
    border: none;
    background-color: $dark-gray;
    height: auto;
    width: 16px;
}
.delete-btn:hover > div{
    @include imageLexnow($delete-image-active);
    border: none;
    background-color: $dark-yellow;
    height: auto;
    width: 16px;
}
.update-btn > div{
    @include imageLexnow($refresh-image-active);
    border: none;
    background-color: $dark-gray;
    height: 24px;
    width: 24px;
}
.update-btn:hover > div{
    @include imageLexnow($refresh-image-active);
    border: none;
    background-color: $dark-yellow;
    height: 24px;
    width: 24px;
}
.subscribe-btn > div{
    @include imageLexnow($subscribe-image-active);
    border: none;
    background-color: $dark-gray;
    height: 24px;
    width: 24px;
}
.subscribe-btn:hover > div{
    @include imageLexnow($subscribe-image-active);
    border: none;
    background-color: $dark-yellow;
    height: 24px;
    width: 24px;
}
.compare-btn > div{
    @include imageLexnow($document-exchange-image-active);
    border: none;
    background-color: $dark-gray;
    width:24px;
    height:24px;
}
.compare-btn:hover > div{
    @include imageLexnow($document-exchange-image-active);
    border: none;
    background-color: $dark-yellow;
    width:24px;
    height:24px;
}
.validate-btn > img{
    @include imageLexnow($ok-image-active);
    border: none;
    background-color: $dark-gray;
    float: none;
}
.validate-btn:hover > img{
    @include imageLexnow($ok-image-active);
    border: none;
    background-color: $dark-yellow;
    float: none;
}
.jurisprudence-btn {
    background-color: $lighter-gray;
    border-color: $dark-gray;
    color: $dark-gray;
    float: none;
}
.jurisprudence-btn > img{
    @include imageLexnow($play-image-disabled);
    width: 16px;
    float: none;
}
.jurisprudence-btn:hover {
    border: none;
    background-color: $dark-yellow;
    float: none;
}


/** CHECKBOX - RADIOBUTTON **/

.documentType-checkbox {
    margin-right: 5px;

    .input-group {
        background-color: $lighter-gray;
        padding: 5px 2px 5px 0;
        margin: 0 5px 5px 0;
        display: inline-table;
        border-radius: 4px;

        .input-group-addon {
            background-color: $transparent;
            border: none;
            width: auto;
            padding: 6px 0 6px 10px;

            input[type="checkbox"], input[type="radio"] {
                display: none;
            }
        }
        .document-type-label {
            margin: 5px 0;
            vertical-align: middle;
            display: block;
            padding-right: 10px;
        }
    }
    .input-group:last-child {
        margin-right: 0;
    }
}

.severance-radioBtn {
    .input-group {
        background-color: $transparent;
        padding: 5px 2px 5px 0;
        margin: 0 5px 5px 0;
        display: inline-table;
        border-radius: 4px;

        .input-group-addon {
            background-color: $transparent;
            border: none;
            width: auto;
            padding: 0;

            input[type="radio"] {
                display: none;
            }
        }
        .severance-label {
            margin: 0 10px;
            padding-right: 10px;
        }
    }
    .input-group:last-child {
        margin-right: 0;
    }
}
li.page-item a.page-link{
  display: flex;
  align-items: center;
}
