.adminLicenseBook {

    .filter-panel {
      margin-top: 30px;
      label {
        line-height: 35px;
      }
      .btn:last-child {
        margin-right: 20px;
      }
    }
    .deactivated{
        color:$light-gray;
        font-style:italic;
    }
    table {
      th, td {
        width: 18%;
      }
      th:last-child, td:last-child {
        width:15%;
        padding-top: 10px !important;
      }
    }
}