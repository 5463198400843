
/************ OFFLINE *****************/

.important-label{
    margin-left: 15px;

    p {
        color: $red;
        a {
            color: $red;
            text-decoration: underline;
        }
    }
}



/************ ADMIN *****************/
#mainContent {
    .container.adminCalculator {
        padding-top: 0 !important;
        height: auto;

        .adminPanel {
            min-height: 0 !important;

            .heading {
                font-weight: $bold-font-weight;
                margin: 10px;
                font-size: $smaller-text;
            }

            table thead {
                background-color: $lighter-yellow;
                height: 40px;
                line-height: 40px;
            }
        }

        .calculatorsTable  {
            width: 100%;
            border-spacing: 0;
            border: 1px solid $dark-gray;

            thead, tbody, tr, th, td {
                display: block;
            }

            thead tr {
                display: inline-block;
                width: 100%;
                /* minus scroll bar width */
                width: -webkit-calc(100% - 17px);
                width:    -moz-calc(100% - 17px);
                width:         calc(100% - 17px);
            }

            tr:after {
                /* clearing float */
                content: ' ';
                display: block;
                visibility: hidden;
                clear: both;
            }

            tbody {
                height: 320px;
                overflow-y: auto;
                overflow-x: hidden;
            }

            tbody td, thead th {
                width: 26%;  /* 24% is less than (100% / 4 cols) = 25% */
                float: left;
            }

            tbody td:first-child, thead th:first-child {
                width: 18%;  /* 24% is less than (100% / 4 cols) = 25% */
            }

            th, td {
                text-align: center !important;
            }

            tbody tr:first-child {
                margin-top: 10px;
            }
        }
    }

    .adminCalculator.modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .modal-p {
                        margin: 0 20px 10px 20px;
                    }
                    .form-group {
                        margin: 10px 0;
                        padding: 0;
                    }
                    .form-group.last {
                        margin: 20px 10px 10px;
                    }
                    .form-group {
                        label {
                            width: 35%;
                            float: left;
                        }
                        .form-control {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}



/************ GENERAL *****************/
.calculator {
    .nameCase {
        label {
            font-size: $small-text;
        }
    }

    .calculator-content {
        width: 100%;
        float: left;
        padding-top: 10px;

        .panel {
            margin: 10px 0 0 0 !important;
            border-radius: 10px;
            border: none;
            box-shadow: 0 2px 2px rgb(30 48 81 / 6%);
            background-color: #fff;
        }
    }

    .details {
        padding: 0 0 15px 10px;
    }

    .sub-panel {
        margin-top: 20px;
        margin-bottom: 0;
        padding-bottom: 15px;
        border: 1px solid $light-gray;
        border-radius: 4px;

        .heading {
            font-weight: $bold-font-weight;
            margin : 10px 10px 20px 10px;
            font-size: $small-text;
        }
        .form-group:not(.heading)  {
            font-size: $smaller-text;
            margin-bottom: 5px;
        }
    }
    .col-sm-12 {
        padding-right: 0;
    }
    .bottomDiv {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .resultCompute .sub-panel {
        height: 535px !important;
        margin-top: 10px;
        margin-right: 0;
    }
}

.calculateBtn {
    float: right;
    margin-top: 15px;
    width: 50px;
}

.calculatorCenterBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .messageToCalculate {
        font-size: smaller;
        color: red;
        padding-left: 5px;
    }
}

.calculatorCenterBtn .actionBtn {
    padding: 0;
}

.centered {
    min-height: 40px;
    line-height: 40px;
}

.linkDetails {
    float: right;
    text-align: right;

    a {
        padding: 0;
        color: $text-color;
        font-size: $small-text;
        height: 34px;
        line-height: 34px;
    }

    a:active, a:hover {
        color: $text-color;
    }
}

.caseReport {
    padding: 0 !important;

    #reportTitle {
        text-align: center;
        font-size: $extra-large-text !important;
        margin: 10px;
    }

    .sub-panel {
        padding-bottom: 0;
    }
}


    /************ INDEXING *****************/
.indexing {
    .nameCase {
        margin-left: 0;
    }
    .rightPanel {
        .scrolledTable {
            min-height: 100px;

            table {
                width: 100%;
                margin: 0 0 15px 0;

                thead > tr {
                    height: 40px;
                    background-color: $light-gray;
                }
                thead tr > th, tbody tr td > input {
                    text-align: center;
                }
                tbody tr td > div {
                    align-items: center;
                    justify-content: center;
                }
                tbody > tr {
                    height: 42px;
                    text-align: center;

                    .settlementDate {
                        border: none;
                        width: 175px !important;
                        margin: 0 15px;
                    }

                    .settlementAmount {
                        border: none;
                        width: 70px;
                    }
                }
            }
        }
        .button-group-footer {
            text-align: center;
            margin-top: 10px;
        }
    }

    .leftPanel {
        height: 230px;

        .amount {
            padding: 0 18px;
            margin-bottom: 5px;
        }
    }

    .leftPanel, .rightPanel {
        padding-top: 10px !important;
    }
}

.caseReport {
    .indexing {
        .sub-panel {
            overflow-x: hidden;
            width: 100%;

            table {
                margin-bottom: 15px;

                .indexingRowTitle {
                    padding: 10px;
                }

                .caseReportFooter {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    font-size: $large-text;
                }

                .caseReportRow {
                    font-size: $large-text;

                    .remainderTitle {
                        padding-top: 7px !important;
                        border-top: 1px solid $text-color;
                    }
                }

            }
        }

        .button-group-footer {
            text-align: right;
            margin-top: 10px;
        }
    }
}


    /************ INTEREST *****************/
.interest {
    label.radio:hover, input:hover {
        cursor: pointer;
    }

    .calculator-content > div {
        padding: 0 5px;
    }

    .calculator-content {
        .middlePanel, .rightPanel {
            height: 430px;
        }
        .middlePanel {
            .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2,
            .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4,
            .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6,
            .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7,
            .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9,
            .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11,
            .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {

                padding-right: 10px;
                padding-left: 10px;
            }

            .interestForm-radio {
                label {
                    font-weight: normal;
                }
                input, label {
                    margin-right: 10px;
                }
            }
        }

        .postsList {
            margin-top: 10px;
            margin-bottom: 10px;

            .radio {
                margin-top: 0 !important;
                margin-bottom: 0 !important;
            }

            li {
                list-style-type: none;
                padding: 5px;
                color: $text-color;
                border: none;
            }

            li:hover, li.activePost {
                font-size: $large-text;
                border: 2px solid $gray;
            }
        }

        .rightPanel {
            position: relative;

            .postTypes {
                margin: 0 10px;

                input[type="radio"], label{
                    vertical-align: middle;
                    margin: 0 10px;
                    height: 40px;
                }

                #legal-majorated {
                    .control-group {
                        display: inline-block;
                    }

                    #legalByFirst, #legalBySecond {
                        align-self: center;
                        width: 25%;
                    }
                    .majoratedBy {
                        padding: 0 5px;
                        font-size: $extra-small-text;
                        text-align: center;
                    }
                    .points {
                        padding: 0 0 0 5px;
                        font-size: $extra-small-text;
                        text-align: center;
                        line-height: 30px;
                    }
                }

                #application {
                    label {
                        padding: 0 5px 0 0;
                        margin: 0;
                        float: left;
                    }
                    #daysNumberType, span {
                        float: left;
                    }
                    #daysNumberType {
                        width: 33%;
                        margin: 5px 0;
                    }
                    span {
                        padding-left: 7px;
                    }
                }
            }

            .calculatorCenterBtn {
                position: absolute;
            }
            .bottomButton {
                bottom: 35px;
            }
        }
    }
}

.caseReport {
    .interest {
        .reportContent {
            margin: 10px;

            .caseReportSubtitle {
                font-size: $small-text;
                font-weight: $bold-font-weight;
                margin-bottom: 10px;
            }
            .spaceLineSmall {
                line-height: 1.5;
            }
            .spaceLineBig {
                line-height: 2;
            }
            .underline {
                text-decoration: underline;
            }
            .bulletCircle {
                list-style-type: circle;
            }
            .totalInterests {
                font-size: $large-text;
                font-weight: $bold-font-weight;
                padding: 10px 0;
                margin-top: 10px;

                span {
                    padding-top: 10px;
                }
            }
        }

        .finalTotalInterests {
            position: relative;
            text-align: right;
            font-size: $larger-text;
            font-weight: $bold-font-weight;
            padding: 10px 15px;
            margin: 0 10px;
        }
        div.finalTotalInterests:before {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 1px;
            width: 35%;
            border-top: 1px solid $light-gray;
        }
    }
}

.jurisprudences {
    .jurisprudence {
        margin-bottom: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px;

        .document {
            .title {
                font-weight: $bold-font-weight;
                font-size: $larger-text;
            }
            .documentType {
                color: $green;
                font-size: $normal-text;
                border-bottom: 1px solid $dark-gray;
                margin-bottom: 15px;

                .inline {
                    display: inline-block;
                }
                .date {
                    text-align: right;
                    font-size: $normal-text;
                    color: $green;
                }
            }
            .jurisprudence-btn {
                span:last-child {
                    padding-left: 5px;
                    color: $dark-gray;
                }
            }
        }
    }
}
