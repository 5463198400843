// Third-party
$screen-xs-max: 767px;
$screen-sm-min: 768px;
$screen-sm-max: 1279px;
$screen-md-min: 1280px;
$screen-md-max: 1440px;
$screen-lg-min: 1441px;

// Root
@import "root/root";

// Variables
@import "modules/colors";
@import "modules/images";
@import "modules/mixins";
@import "modules/fonts";
@import "modules/layout";
@import url("https://use.typekit.net/etk1csa.css");

// General layout
@import "layout/buttons";
@import "layout/headers";
@import "layout/modals";
@import "layout/bootstrap5-override";

// Partials
@import "partials/spinner";
@import "partials/scrolls";
@import "partials/breadcrumb";
@import "partials/base";
@import "progilexV1/main";
@import "partials/swiper";

