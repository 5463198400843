.adminLicense {
  padding-top: 0 !important;
  height: auto;

  table {
    input[type='checkbox'] {
      top: 0px;
    }

    th:first-child, td:first-child {
      width: 10%;
    }
    th, td {
      width: 40%;
    }
    th:last-child, td:last-child {
      width: 50%;
    }
  }
  .btn.pull-right {
    margin-left: 20px;
    margin-right: 0;
  }
}