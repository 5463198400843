.lx-menu-padding{
  @media(min-width: 430px) {
    padding-right: 160px;
  }
}

.adminBookLicenseCompany {

    .deactivated{
        color:$light-gray;
        font-style:italic;
    }

    table {
      width: 100%;
      tr {
        border: 1px solid #dddddd;
        th, td {
          width: 28%;
          padding-left: 20px;
        }
        th:last-child, td:last-child {
          width:15%;
        }
      }
      
    }
  svg {
    width: 10px;
    height: 12.5px;
  }
  svg line{
    stroke: white;
  }
  svg line:last-child{
    display: none;
  }
  a.collapsed > span.badge > svg line:last-child {
    display: block;
  }
}