h1, h2, h3, h4, h5, h6 {
	font-weight: $fw-bold !important;
}
h1, .h1 {
    font-size: $fs-biggest !important;
}
h2, .h2 {
    font-size: $fs-bigger !important;;
}
h3, .h3 {
    font-size: $fs-big !important;;
}
h4, .h4 {
    font-size: $fs-medium !important;;
}
h5, .h5 {
    font-size: $fs-small !important;;
}
h6, .h6 {
    font-size: $fs-smaller !important;;
}

.lexnow.header {
    h4 {
        font-size: $fs-biggest !important;
        padding-top: 2%;
        padding-bottom: 2%;
        margin: 0;
        color: $blue-darker;
    }
    @media (max-width:$screen-xs-max) {
        h4 {
            padding-top: 0;
        }
    }
}
