
/*
 * Gestion des marqueurs des documents
 * =============================================================================
 */
.mark {
  display: inline;
}
.progilex-v1 .no-parag, .no-consid {
  display: inline;
  margin-right: 5px;
  font-size: 13px;
  font-weight: bold;
}
.progilex-v1 .linkeddoc {
  margin: 0px;
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 0px;
  width: 95%;
}
.progilex-v1 .linkeddoc tr td {
  margin: 0px;
  padding-top: 2px;
  padding-bottom: 0px;
  font-size: 14px;
  text-align: right;
  font-style: italic;
  font-weight: normal;
}
.progilex-v1 .linkeddoc tr td a {
  text-decoration: underline;
  font-weight: bold;
}
.progilex-v1 .linkdocparl {
  display: inline;
  text-decoration: underline;
  cursor: pointer;
  font-weight: normal;
}
.progilex-v1 .linkdir {
  display: inline;
  text-decoration: underline;
  cursor: pointer;
  font-weight: normal;
}
.progilex-v1 .linkdoc {
  display: inline;
  text-decoration: underline;
  color: $blue-darker;
}
.progilex-v1 .linkextern_other_wks, .progilex-v1 .linkextern_other_wks2,
.progilex-v1 .linkextern_other_wks3, .progilex-v1 .linkextern_other_wks4  {
  display: inline;
  color: $blue-darker;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  font-style: italic;
}
.progilex-v1 .linkextern_other_wks2 {
  color: #4b2582;
}
.progilex-v1 .linkextern_other_wks3 {
  color: #659ca6;
}
.progilex-v1 .linkextern_other_wks4 {
  color: #f18e00;
}
.progilex-v1 .linkextern_wks, .progilex-v1 .linkextern_wks2,
.progilex-v1 .linkextern_wks3, .progilex-v1 .linkextern_wks4 {
  display: inline;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  font-style: italic;
}
.progilex-v1 .linkextern_wks {
  color: $green;
}
.progilex-v1 .linkextern_wks2  {
  color: #4b2582;
}
.progilex-v1 .linkextern_wks3 {
  color: #659ca6;
}
.progilex-v1 .linkextern_wks4 {
  color: #f18e00;
}
.progilex-v1 .b {
  font-weight: bold;
}
.progilex-v1 .i {
  font-style: italic;
}
.progilex-v1 .u {
  text-decoration: underline;
}
