
/*
 * Gestion des titres
 * =============================================================================
 */
.progilex-v1 {
  h1, .h1 {
    font-size: 18px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 5px;
  }
  h2, .h2 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
    color: $blue-darker !important;
    text-transform: none !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    padding: 0 0 !important;
  }
  h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-size: 15px;
    margin-top: 4px;
    margin-bottom: 2px;
  }
  h5, .h5 {
    font-weight: bold;
    margin-left: 2px;
  }
  h6, .h6 {
    font-size: 14px;
    font-style: italic;
    margin-left: 5px;
  }
  .heading-summary {
    font-weight: bold;
    font-size: 15px;
  }
  .summary {
    .h1, .h2, .h3, .h4, .h5, .h6 {
      display: block;
      margin: 0px;
      padding: 0px;
      text-align: left;
      font-size: 14px;
    }
  }
}


/*
 * Uniformisation des titres des documents
 * =============================================================================
 */

.progilex-v1 {
  .ti-loi, .ti-convention, .ti-protocol, .intitule, .title, .title .p, .ti-annexe, .ti-annexes, .ti-avenant {
    display: block;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 5px;
  }

  .intitule * {
    font-size: 16px;
  }

  .intitule h1 {
    font-size: 24px;
    color: #1E3051;
  }

  .intitule h2 {
    font-size: 20px;
    color: #1E3051;
  }

  .ti-annexes {
    font-size: 18px;
    padding-bottom: 10px;
  }
  .ti-annexe {
    padding-top: 10px;
  }
  .ti-convention, .ti-protocol, .ti-avenant {
    padding-top: 20px;
  }
  .introduction {
    display: block;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    font-style: italic;
    padding-bottom: 5px;
  }
}


/*
 * Titre des articles
 * =============================================================================
 */
.progilex-v1 .ti-article {
  display: inline-block;
  font-weight: bold;
  margin-left: 5px;
}

